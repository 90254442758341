import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

class ToggleList extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired
  };

  state = {
    toggleDescription: false
  };

  onClick = () => {
    this.setState({ toggleDescription: !this.state.toggleDescription });
  };

  onKeyPress = (ev) => {
    if (ev.keyCode === 32) {
      this.onClick();
    }
  };

  render() {
    const { item } = this.props;
    const { toggleDescription } = this.state;

    return (
      <div className={styles.wrap}>
        <button className={styles.item} onKeyPress={this.onKeyPress} onClick={this.onClick}>
          {item.title.toUpperCase()}
        </button>
        {toggleDescription &&
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        }
      </div>
    );
  }
}

export default ToggleList;
