import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { toast } from 'react-toastify';

class LiqPay extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    processLiqpayPaymentSuccess: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { location, history } = this.props;
    const { data, signature } = parse(location.search);
    if (!data || !signature) {
      history.push('/cart');
      return;
    }

    window.LiqPayCheckout
      .init({
        data,
        signature,
        embedTo: '#liqpay',
        language: 'ru',
        mode: 'embed' // embed || popup
      })
      .on('liqpay.callback', (res) => {
        if (!res || !res.order_id) {
          toast.error('Оплата прошла неуспешно. С вами свяжется наш менеджер', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          return;
        }

        this.props
          .processLiqpayPaymentSuccess({ variables: { input: { order_id: res.order_id } } })
          .then(({ data: { processLiqpayPaymentSuccess } }) => {
            if (!processLiqpayPaymentSuccess) {
              toast.error('Данные об оплате не были сохранены. С вами свяжется наш менеджер', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });
              return;
            }

            history.push('/cart/success');
          })
          .catch(() => {
            toast.error('Данные об оплате не были сохранены. С вами свяжется наш менеджер', {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          });
      })
      .on('liqpay.ready', () => {
        // ready
      })
      .on('liqpay.close', () => {
        // close
      });
  }

  render() {
    return <div id='liqpay' />;
  }
}

export default LiqPay;
