import React from 'react';
import PropTypes from 'prop-types';
import CartAmount from '../cartAmount';
import styles from './styles.css';

const CartProduct = ({ product, amountOnChange, removeProduct }) => (
  <div className={`${styles.wrapper} container-fluid`}>
    <button
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        removeProduct(product.id);
      }}
      className={styles.close}
    >
      x
    </button>
    <div className="row">
      <div className="col-xs-6">
        <img className={styles.image} src={product.images[0].path} alt="" />
      </div>
      <div className={`col-xs-6 ${styles.title}`}>
        <div className={styles.type}>{product.type}</div>
        <div className={styles.name}>
          <strong>{product.name}</strong>
        </div>
        <CartAmount amount={product.amount} id={product.id} amountOnChange={amountOnChange} />
        <div className={styles.price}>
          <strong>{product.price * product.amount} грн.</strong>
        </div>
      </div>
    </div>
  </div>
);

CartProduct.propTypes = {
  product: PropTypes.object.isRequired,
  amountOnChange: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired
};

export default CartProduct;
