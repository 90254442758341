import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './styles.css';

const BreadCrumb = ({ href, name }) => (name && href ?
  <Link className={styles['crumb-link']} to={href}>
    {name}
  </Link>
  : null
);

BreadCrumb.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string.isRequired,
};

BreadCrumb.defaultProps = {
  href: ''
};


export default BreadCrumb;
