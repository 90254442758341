import React from 'react';
import PropTypes from 'prop-types';
import FilteredProducts from '../filteredProducts';
import Links from './components/links/component';

const Sort = (
  {
    attributes,
    products,
    match,
    location,
    addProductToCart,
    buy
  }
  ) => (
    <div className="col-xs-12 col-lg-9">
      <Links attributes={attributes} match={match} location={location} />
      <FilteredProducts
        match={match}
        location={location}
        products={products}
        attributes={attributes}
        addProductToCart={addProductToCart}
        buy={buy}
      />
    </div>
);

Sort.propTypes = {
  attributes: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  buy: PropTypes.func.isRequired
};

export default Sort;
