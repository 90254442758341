import React from 'react';
import PropTypes from 'prop-types';

import Social from './components/social';
import Contacts from './components/contacts';
import Description from './components/description';
import Categories from './components/categories';
import Menu from './components/menu';
import styles from './styles.css';

const Footer = ({ info, social, contacts, categories, phones }) => (
  <div className={styles.footer}>
    <div className={styles.background} />
    <div className="container">
      <Social social={social} />
      <div className={styles['footer-content']}>
        <Menu menu={info} />
        <Categories categories={categories} />
        <Contacts contacts={contacts} phones={phones} />
        <div className={`${styles['footer-row']}`}>
          <Description />
        </div>
      </div>
      <div className={`${styles.copyright} col-xs-12`}>
        © horoshka.com.ua
      </div>
    </div>
  </div>
);

Footer.propTypes = {
  info: PropTypes.object.isRequired,
  social: PropTypes.object.isRequired,
  contacts: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  phones: PropTypes.array.isRequired
};

export default Footer;
