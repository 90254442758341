import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

const CartFormRadio = ({ options, name, onChange, value }) => (
  <div className={styles.wrapper}>
    {options.map(option => (
      <label
        key={option.id}
        htmlFor={option.id}
        className={styles.label}
      >
        <input
          id={option.id}
          className={styles.input}
          name={name}
          type="radio"
          value={option.value}
          checked={option.value === value}
          onChange={onChange}
        />

        <span className={styles['label-text']}>{option.label}</span>
      </label>
    ))}
  </div>
);

CartFormRadio.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CartFormRadio;
