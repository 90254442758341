import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles.css';

class Menu extends Component {
  static propTypes = {
    info: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.wrapperRef = createRef();
  }

  state = {
    isOpen: false
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  onClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  UNSAFE_componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickout = () => {
    this.setState({ isOpen: false });
  };

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !(this.wrapperRef && this.wrapperRef.current
        && this.wrapperRef.current.contains(event.target))
    ) {
      this.handleClickout();
    }
  }


  render() {
    return (
      <button className={`${styles.menu}`} onClick={this.onClick}>
        <div className={styles.line} />
        {this.state.isOpen &&
          <div className={styles.wrap} ref={this.wrapperRef}>
            {this.props.info && this.props.info.links.map(item => (
              <Link to={item.url} className={styles.item} key={item.id}>
                {item.name}
              </Link>
            ))}
          </div>
        }
      </button>
    );
  }
}

export default Menu;
