import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './styles.css';

const Topper = ({ social, info }) => (
  <div className={styles.topper}>
    <div className={`${styles.wrap} container col-12`}>
      <div className={`${styles.left}`}>
        {info && info.links.map(link => (
          <NavLink
            activeClassName={styles.active}
            className={styles.info}
            to={link.url} key={link.id}
          >
            {link.name}
          </NavLink>
        ))}
      </div>
      <div className={`${styles.right}`}>
        {social && social.links.map(link => (
          <a
            href={`//${link.url}`}
            key={link.id}
            target="_blank"
            rel="noopener noreferrer"
            style={{ backgroundImage: `url(images/${link.name})` }}
            className={styles.social}
          >
            {''}
          </a>
        ))}
      </div>
    </div>
  </div>
);

Topper.propTypes = {
  social: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired
};
export default Topper;
