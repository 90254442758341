import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Filter from '../../components/filter';
import styles from './styles.css';

const FilterPanel = (
  {
    match,
    location,
    attributes,
    products,
    filterOn,
    toggleFilter,
    history
  }) => (
    <div className="col-lg-3">
      <div className={`${styles['filter-buttons-wrap']}`}>
        <button onClick={toggleFilter} className={styles['toggle-filter']}>
          ФІЛЬТР
          <img
            alt=""
            src="/images/little-arrow.svg"
            className={`${styles.arrow} ${filterOn && styles['arrow-down']}`}
          />
        </button>
        <Link
          className={
            `${styles.reset}
            ${location.search && styles['active-reset']}
            col-xs-5
            col-sm-4
            col-md-3
            col-lg-12`
          }
          to={{
            path: match,
            search: ''
          }}
        >
          {'Скинути'} <span className={styles['hidden-part']}>Фільтри</span>
        </Link>
      </div>
      <Filter
        attributes={attributes}
        match={match}
        location={location}
        history={history}
        products={products}
        filterOn={filterOn}
      />
    </div>
);

FilterPanel.propTypes = {
  attributes: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  filterOn: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default FilterPanel;
