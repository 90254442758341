import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageWithFilter from '../pageWithFilter/component';

const Manufacturer = (
  {
    products,
    match,
    location,
    manufacturer,
    history,
    addProductToCart,
    buy
  }) => {
  if (manufacturer === null) {
    return <Redirect to="/404" />;
  }
  return (
    <PageWithFilter
      match={match}
      location={location}
      history={history}
      addProductToCart={addProductToCart}
      products={products}
      pageTitle={manufacturer.value}
      buy={buy}
      metaTags={{
        metaDescription: manufacturer.metaDescription,
        metaTitle: manufacturer.metaTitle,
        canonical: manufacturer.canonical,
      }}
      footerText={manufacturer.footerText}
    />
  );
};

Manufacturer.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  manufacturer: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  buy: PropTypes.func.isRequired
};

export default Manufacturer;
