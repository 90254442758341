import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import cartQuery from './cartQuery.graphql';
import saveOrder from './saveOrder.graphql';
import saveOrderWithLiqpay from './saveOrderWithLiqpay.graphql';
import component from './component';
import { compose } from '../../common/utils';

export default withRouter(compose(
  graphql(cartQuery, {
    options: () => ({
      variables: {
        ids: (() => {
          const cart = localStorage.getItem('cart');
          if (!cart || cart.length === 0) { return []; }
          return JSON.parse(cart).map(product => product.id);
        })()
      }
    }),
    props: ({ data: { cart = [] } }) => {
      const cartStorage = localStorage.getItem('cart');
      const cartArrStorage = JSON.parse(cartStorage);
      return {
        cart: cart.map(product => ({
          ...product,
          amount: cartArrStorage.find(storageProduct => storageProduct.id === product.id) &&
            cartArrStorage.find(storageProduct => storageProduct.id === product.id).amount
        }))
      };
    }
  }),
  graphql(saveOrder, { name: 'saveOrder' }),
  graphql(saveOrderWithLiqpay, { name: 'saveOrderWithLiqpay' })
)(component));

