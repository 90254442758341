export default {
  deliveryAndPay: 'Доставка та оплата',
  fullDescription: 'Повний опис',
  characteristics: 'Характеристики',
  callbackSaveSuccess: 'Дякуємо, ми надішлемо Вам листа чи зателефонуємо',
  callbackSaveFailure: 'Виникла помилка',
  redirectToMain: 'Повернутися на головну сторінку',
  order: {
    success: {
      header: 'Замовлення отримано!',
      message: 'Ваше замовлення отримано та вже обробляється менеджером. Дякуємо!'
    }
  },
  callbackForm: {
    success: {
      header: 'Ваше повідомлення отримано та вже обробляється менеджером. Дякуємо!',
    }
  },
  aboutTitle: 'Про Хорошку',
  aboutSubtitle: 'Наша мета і команда',
  aboutDescription: `
    <p>Хорошка» - інтернет-магазин найрізноманітніших товарів для ваших діток! Ми з любов'ю пропонуємо вам якісну сертифіковану дитячу продукцію вітчизняних і зарубіжних виробників.</p>
  
    <p>Асортимент магазину постійно розширюється. Коляски та меблі, автокрісла і дитячий транспорт, іграшки, а також товари для дбайливих батьків - на будь-який смак, колір, побажання та можливості! Ми намагаємося тішити українські сім'ї найсучаснішими, часто інноваційними пропозиціями провідних компаній-виробників. Проте, завжди пам'ятаємо і про товари, перевірених часом, завоювали визнання багатьох батьків і дітей.</p>
    
    <p>Команда «Хорошки» готова стати вірним другом крихітки з перших днів життя, а вам, шановні батьки - компетентними помічниками у виборі речей, які стануть у нагоді саме для вашою дитини.</p>
    
    <p>Підростають дітки наших клієнтів, а разом з ними - «Хорошка». Зростає і розвивається, залишаючись все тим же близьким і тямущим, веселим і таким дитячим магазином! Магазином, який завжди радий вам, вашим листів і дзвінків, а найголовніше - вашим дорогим малюкам.</p>`,
  aboutAddress: 'Карта проїзду:',
  aboutContacts: 'Наші контакти:',
  aboutEmail: 'horoshka.info@gmail.com',
  aboutEmailTitle: 'Наша пошта:',
  delivery: [
    {
      link: 'Коли та як здійснюється доставка товару?',
      text: `
        <p>Доставка товарів, придбаних у нашому магазині, здійснюється по всій Україні кур'єрськими службами на вибір Клієнта - Нова пошта, Делівері, Укрпошта і т.д..</p>
  
        <p>Доставка здійснюється за умовами та відповідно до режиму роботи обраної кур'єрської служби. Доступна доставка післяплатою. Після оформлення замовлення наш менеджер обов'язково зв'яжеться з Вами для обговорення деталей доставки та запропонує найзручніший варіант.</p>
        
        <p>Для мешканців міста Рівного доступна послуга самовивезення із нашого шоуруму у робочий час. При купівлі на суму понад 5000 грн. доступна послуга безкоштовної кур'єрської доставки до під'їзду по Рівному.</p>
        
      `
    },
  ],
  '404Description': 'Сторінка не знайдена',
  '404Title': '404',
  'how-to-order-title': 'Допомога',
  'how-to-order': [
    {
      title: 'Як зробити замовлення?',
      description: '<p>Ви можете оформити замовлення через кошик сайту, по телефону у менеджера або у наших соц мережах.</p>'
    },
    {
      title: 'Як оформити замовлення по телефону або через месенджери?',
      description: '<p>Ви можете зателефонувати по одному з вказаних номерів +38(063)066-93-36, +38(096)689-88-98, +38(099)969-45-58. Якщо зручніше зробити замовлення в текстовому режимі, ми додамо Вас у чат до менеджера, де ви зможете оформити замовлення та дізнатися деталі по товару.</p>'
    },
    {
      title: 'Як швидко зі мною звяжуться?',
      description: '<p>Наш менеджер звяжеться з Вами у робочі години з 10:00 до 18:00 або на наступний день у разі оформлення замовлення у неробочі години.</p>'
    },
    {
      title: 'Який у вас графік роботи?',
      description: '<p>Стаціонарний магазин працює з вівторка по неділю з 10:00 по 18:00. Онлайн замовлення оформлюються також у робочі години. </p>'
    },
  ],
  'how-to-order-footer': `<p>Не знайшли відповіді на ваші питання?
                            Звяжіться з нами будь-яким зручнім способом (по телефону, у соціальних мережах чи заповнивши форму)</p>`,
  'guarantee-title': 'Гарантія',
  guarantee: [
    {
      title: 'Чи надається гарантія на ваші товари?',
      description: `<p>Гарантія на наші товари надається фірмою-виробником товарів</p>
                    <p>Повернення та обмін здійснюються згідно норм чиного законодавства.</p>
                    <p>Гарантійний термін починається з дня продажу і діє встановлений виробником термін. дата продажу вказана на чеку та/або у гарантійному талоні, якщо такий передбачається виробником.</p>
                    <p>Гарантійний ремонт здійсняюється сертифікованний фірмою-виробником сервісним центром.</p>
                    <p>Даставка на гарантійний ремонт здійснюється покупцем.</p>`
    },
    {
      title: 'У яких випадках гарантія не здійснюється?',
      description: `<ul>
                        <li>Якщо порушуються правила вимог щодо експлуатації даного товару.</li>
                        <li>Якщо виріб має сліди стороннього втручання або була спроба ремонту у неспеціалізованому сервісному центрі.</li>
                        <li>Якщо виріб був пошкоджений внаслідок падіння, транспортування, зберігання, нещасного випадку, домашніми тваринами або використовувався не за призначенням.</li>
                        <li>Якщо пошкодження сталося внаслідок потрапляння всередину сторонніх предметів, рідин, речовин.</li>
                        <li>Якщо виріб використовується у професійних, промислових або комерційних цілях та режимах непередбачених навантажень.</li>
                    </ul>`
    },
    {
      title: 'Чи можу я обміняти чи повернути товар?',
      description: `<p>
                        <p>Товар можна повернути протягом 14 днів з дня покупки, за умови дотримання таких умов</p>
                        <ul>
                          <li>Товар не був у вжитку, збережений товарний вигляд, споживчі властивості, пломби, фабричні ярлики, упаковка не пошкоджена.</li>
                          <li>Пред'явлено документ, що підтверджує факт, дату та умови купівлі товару.</li>
                        </ul>
                        <p>У разі виявлення браку, неповної комплектації або інших недоліків, ми безкоштовно обміняємо Ваш товар на товар належної якості.</p>                        
                        <p>Если заказанный Вами товар, который подлежит обмену, в данный момент отсутствует на складе, наш интернет-магазин гарантирует возврат денежных средств.</p>                        
                        <p>Повернення товару здійснюється за адресою, вказаною менеджером, з обов'язковим попереднім повідомленням менеджера про наміри повернути товар.</p>                        
                        <p>У разі обміну та повернення товару належної якості, Ви сплачуєте витрати на доставку товару до Вас та доставку товару від Вас.</p>                        
                    </p>`
    },
  ],
  'guarantee-footer': `<p>Не знайшли відповіді на своє запитання?</p>
                       <p>Задайте його нам тут або зателефонуйте за номером +38 (099) 969-45-58 і ми негайно Вам відповімо.</p>`,
  'delivery-title': 'Доставка и оплата',
  'delivery-items': [
    {
      title: 'Коли та як здійснюється доставка товару?',
      description: `<p>
                        <p>Доставка товарів, придбаних у нашому магазині здійснюється по всій Україні кур'єрськими службами (Інтайм, Делівері, Нова пошта, Укрпошта).</p>
                        <p>Доставка здійснюється у робочі дні з 9:00 до 19:00.</p>
                        <p>У місті Рівне доступна послуга самовивезення з нашого шоуруму у робочий час.</p>
                        <p>Для мешканців Рівного при покупці від 5000 грн доступна послуга безкоштовної кур'єрської доставки до під'їзду.</p>
                    </p>`
    },
    {
      title: 'Як здійснюється доставка товару по місту Рівному?',
      description: `<p>
                        <p>Після заявки на той чи інший товар за вказаним телефоном або e-mail адресою з Вами зв'яжеться менеджер для підтвердження замовлення, уточнення дати та зручного для Вас часу доставки.</p>
                        <p>Термін здійснення доставки - від 1 до 2 робочих днів. Для деяких груп товарів термін доставки може збільшитись (залежно від наявності обраного товару на складі).</p>
                        <p>До обов'язків кур'єра входить доставка та перевірка комплектації товару. Складання товару можливе в окремих випадках – за попередньою домовленістю та за окрему плату.</p>
                        <p>При сумі замовлення від 5000 грн. доставка здійснюється безкоштовно. За сумою замовлення до 5000 грн. вартість доставки визначається індивідуально.</p>
                        <p>Вартість повторного виїзду по місту Рівному (за обумовленим часом замовник був відсутній) становитиме 30 грн.</p>
                        <p>У випадку, якщо з незалежних від нашого інтернет-магазину причин Ви відмовляєтеся від замовлення в момент його фактичного отримання Вам доведеться сплатити вартість доставки в розмірі 30 гривень.</p>
                    </p>`
    },
    {
      title: 'Як здійснюється доставка товару по Україні?',
      description: `<p>
                        <p>В інші регіони України доставка товарів здійснюється за допомогою транспортних компаній (Нова пошта, Інтайм, Делівері, Укрпошта – на вибір Клієнта).</p>
                        <p>При отриманні посилки, одержувач повинен мати при собі паспорт та знати номер декларації.</p>
                        <p>Номер декларації буде надіслано Вам за допомогою SMS-повідомлення та листа на електронну пошту, яку Ви вказали при оформленні замовлення.</p>
                        <p>Вартість доставки формується компанією-перевізником, виходячи з габаритів, ваги, а також місця призначення доставки.</p>
                        <p>Послуги компанії-перевізника з доставки замовлення Ви оплачуєте на місці, при його отриманні.</p>
                        <p>Зверніть увагу на те, що якщо Ви хочете скористатися послугою післяплати (оплатити товар при отриманні), комісію, яку бере при цьому перевізник, оплачуєте Ви.</p>
                        <p>Ми вважаємо за краще користуватися послугами компаній "Делівері" та "Нова Пошта", тому що ці служби зарекомендували себе з найкращого боку.</p>
                        <p>Якщо Вам бажано отримати замовлення за допомогою певної кур'єрської служби, наш менеджер з радістю це обговорить.</p>
                        <p>На деякі товари діє акція – безкоштовна доставка по Україні. Такі товари у нашому каталозі відмічені прапорцем "Безкоштовна доставка".</p>
                    </p>`
    },
    {
      title: 'Коли та як здійснюється самовивіз товару?',
      description: `<p>
                        <p>Ви можете забрати товар самостійно з нашого шоуруму за адресою – м. Рівне, вул. Замкова 14в.</p>
                        <p>Якщо у Вас будуть труднощі з пошуком складу – менеджер з радістю відповість на Ваші запитання.</p>
                        <p>Якщо Ви бажаєте забрати замовлений Вами товар з нашого складу, проінформуйте, будь ласка, менеджера про це за 1 день перед візитом до нас.</p>
                    </p>`
    },
    {
      title: 'Як можна сплатити товар?',
      description: `<p>
                        <p>Ви можете оплатити товар особисто, післяплатою або ж переказом на банківський рахунок.</p>
                        <p>Оплатити товар особисто Ви можете при самовивезенні або доставці кур'єром.</p>
                        <p>Якщо Ви замовляєте товар в інше місто, то можете оплатити товар за допомогою післяплати (при отриманні).</p>
                        <p>Ви також можете оплатити товар у банку за реквізитами:</p>
                        <p>Рахунок 26002054709640</p>
                        <p>МФО 333391 ПАТ КБ ПриватБанк ЄДРПОУ/ДРФО 3366913033</p>
                        <p>Отримувач: ФОП Хоміч Дмитро Анатолійович</p>
                    </p>`
    }
  ],
  'delivery-footer': `<p>не знайшли відповіді на своє запитання?</p>
                      <p>Задайте його нам тут або зателефонуйте за номером +38 (099) 969-45-58 і ми негайно Вам відповімо.</p>`,
  'homepage-text-2': `
    <ul>
      <li>Особисто відібрані товари для новонароджених і дітей за привабливою ціною.</li>
      <li>Тільки сучасні та безпечні дитячі товари із сертифікатами якості, безпеки та гарантією виробника.&nbsp;</li>
      <li>Надання покупцям професійної консультації та допомога з вибором продукції.</li>
      <li>Оперативна доставка товару у Київ, Дніпропетровськ, Львів, Одесу, Харків та інші населені пункти України.</li>
    </ul>
    <p>Товари для дітей &ndash; це наша щоденна праця та наше кохання. Наша команда - знає, що головне у житті, тому пропонуємо малюкам найкращі товари та економимо час та гроші батькам.<br />
    Хорошка намагається, щоб кожна покупка служила довго і приносила радість як дітям, і дорослим.</p>`,
  'homepage-meta': 'Дитячий інтернет магазин Хорошка ❤ Коляски, ліжечка, іграшки для дітей та новонароджених ❤ Каталог дитячих товарів ✈Доставка у будь-яке місто $Низькі ціни ☎+38(063)066 93 36',
  'homepage-title': '☼ Хорошка ☼ - інтернет-магазин дитячих товарів з доставкою по Україні, низькі ціни',
  'homepage-h1': 'Ласкаво просимо до інтернет-магазину дитячих товарів Хорошка',
  'homepage-sub-header': '<p>У нашому каталозі лише оригінальні товари для дітей та новонароджених від топових світових виробників. Horoshka.com.ua пропонує більше 300 моделей дитячих колясок 40 брендів, меблі для дитячої кімнати, іграшки та транспорт для дітей. Гарантуємо приємні ціни, сертифікати якості на кожен товар та допомогу менеджерів у виборі.</p>',
  'homepage-h2-1': 'Horoshka.com.ua – це дитячий інтернет-магазин та шоу-рум у м.Рівне.',
  'homepage-text-1': `<p>Вже багато років продаємо коляски, ліжечка та іграшки для малюків. Завдяки 10-річному досвіду роботи з дитячими товарами, Хорошка пропонує тільки ретельно відібрані ТОП моделі середньої цінової категорії. українських та зарубіжних виробників. Співпрацюємо безпосередньо з брендами з України та Європи, що дозволяє уникати націнок. Налагоджені ділові контакти з виробниками та дистриб'юторами дозволили надати покупцям нашого дитячого магазину найкращі у своїй категорії продукти та вигідні ціни.<br />
    Наші менеджери це &ndash; це досвідчені батьки, знайомі з кожним товаром на нашій полиці, із задоволенням допоможуть підібрати річ. на будь-який запит та гаманець, задовольнивши потреби&nbsp; дитини та враховуючи побажання батьків.&nbsp;</p>
    <p>Завдяки інтернет-магазину Horoshka, коли у вас з'явиться дитина, у нього буде все необхідне:</p>
    <ul>
      <li>Комфортний візок</li>
      <li>Безпечне автокрісло</li>
      <li>Ліжечко з ортопедичним матрацом і натуральне постільні речі</li>
      <li>Стильні меблі у дитячій&nbsp;</li>
      <li>Зручний стільчик для годування</li>
      <li>Перший велосипед&nbsp;</li>
    </ul>
    <p>Ми постійно працюємо над розширенням асортименту. Нові моделі щоденно поповнюють наш каталог товарів для дітей.</p>
    <p>На сайті horoshka.com.ua є розділ з оглядами дитячої продукції, а також інформація про надходження нових позицій та акції.</p>
    <p>Купити коляску в магазині Horoshka можна в один клік, доставка по Україні займе лише 1-2 робочі дні. Менеджери проконсультують по кожному продукту, допоможуть визначитися з вибором, уточнять можливі комплектації та колірні рішення.&nbsp; Horoshka цінує кожного клієнта, команда завжди готова йти на зустріч та допомогти. кожному вибрати потрібну річ.&nbsp;</p>
  `,
  'homepage-h2-2': 'Переваги покупки в Хорошці',
};
