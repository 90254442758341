import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

const inCart = (addProductToCart, idToCart) => (ev) => {
  ev.preventDefault();
  ev.stopPropagation();
  addProductToCart(idToCart);
};

const addProductInCartAndBuy = (buy, idToCart) => (ev) => {
  ev.preventDefault();
  ev.stopPropagation();
  buy(idToCart);
};

const PricePanel = ({ product, addProductToCart, buy, modification }) => {
  const { active } = product;
  const price = (modification.price && Math.round(modification.price)) || Math.round(product.price);

  const salePrice =
      (modification.salePrice && Math.round(modification.salePrice))
      || Math.round(product.salePrice) || '';

  return (
    <div className={styles.block}>
      <div className={'col-xs-12 col-sm-6'}>
        {salePrice &&
          <div className={`${styles.price} ${styles.sale}`}>
            <span property='price' content={salePrice}>
              {salePrice}
            </span>
            <span property='priceCurrency' content='UAH'>{' грн'}</span>
          </div>
        }
        <div className={`${!salePrice && styles.price} ${salePrice && styles.cross}`}>
          <span property={!salePrice ? 'price' : ''} content={price}>{price}</span>
          <span property={!salePrice ? 'priceCurrency' : ''} content={!salePrice ? 'UAH' : ''}>{' грн'}</span>
        </div>
      </div>
      {active &&
        <div className={`${styles.buttons} row col-xs-12 col-sm-6`}>
          <div className={`${styles.button}  col-xs-6 col-sm-12 col-md-6`}>
            <button
              className={styles['in-basket']}
              onClick={inCart(addProductToCart, modification.id || product.id)}
            >
              В кошик
            </button>
          </div>
          <div className={`${styles.button} col-xs-6 col-sm-12 col-md-6`}>
            <button
              className={styles.buy}
              onClick={addProductInCartAndBuy(buy, modification.id || product.id)}
            >
              Купити
            </button>
          </div>
        </div>
      }
      {!active &&
        <div className={`${styles['no-active']} ${styles.button} row col-xs-12 col-sm-6`}>
          Немає в наявності
        </div>
      }
    </div>
  );
};

PricePanel.propTypes = {
  product: PropTypes.object,
  modification: PropTypes.object,
  addProductToCart: PropTypes.func.isRequired,
  buy: PropTypes.func.isRequired,
};

PricePanel.defaultProps = {
  product: {},
  modification: {}
};


export default PricePanel;
