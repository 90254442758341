import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles.css';

const Categories = ({ categories = [] }) => (
  <div className={`${styles['footer-item']}`}>
    <div className={styles['contacts-title']}>
      Категорії
    </div>
    <div>
      {categories.map(item => (
        <Link
          className={styles.item}
          key={item.id}
          to={`/${item.href}`}
        >
          {item.name}
        </Link>
      ))}
      <Link
        className={styles.item}
        key={'skidki'}
        to={'/skidki'}
      >
        Знижки
      </Link>
      <Link
        className={styles.item}
        key={'manufacturers'}
        to={'/manufacturers'}
      >
        Бренди
      </Link>
    </div>
  </div>
);

Categories.propTypes = {
  categories: PropTypes.array.isRequired
};

export default Categories;
