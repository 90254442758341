import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import productsQuery from './productsQuery.graphql';
import typeQuery from './typeQuery.graphql';
import component from './component';
import { compose } from '../../common/utils';

export default withRouter((compose(
  graphql(productsQuery, {
    options: ({ match }) => ({ variables: { type: match.params.href } }),
    props: ({ data: { products = [] } }) => ({ products })
  }),
  graphql(typeQuery, {
    options: ({ match }) => ({ variables: { href: match.params.href } }),
    props: ({ data: { type = {} } }) => ({ type })
  }))
)(component));
