import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import styles from './styles.css';

const ColorValue = ({ value, attributeId, match, location, onClick }) => {
  const parsedSearch = queryString.parse(location.search);

  if (parsedSearch[attributeId] === value.value.value) {
    delete parsedSearch[attributeId];
  } else {
    parsedSearch[attributeId] = value.value.value;
  }

  return (
    <Link
      key={value.id}
      to={{
        pathname: match.url,
        search: queryString.stringify(parsedSearch)
      }}
      style={{ backgroundColor: value.value.value }}
      className={`
        ${styles.color}
        ${parsedSearch[attributeId] !== value.value.value && styles.active}
        ${parsedSearch[attributeId] === value.value.value && styles.disable}
       `}
      onClick={() => onClick(attributeId, value.value.value)}
    />
  );
};

ColorValue.propTypes = {
  value: PropTypes.object.isRequired,
  attributeId: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ColorValue;
