import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.css';

const Contacts = ({ contacts, phones }) => (
  <div className={`${styles['contacts-wrap']}`}>
    <div className={styles['contacts-title']}>
      Контакти
    </div>
    <div className={styles.contacts}>
      {contacts && contacts.map(contact => (
        <div
          className={styles.contact}
          key={contact.id}
        >
          <div className={styles.arrow} />
          <div className={styles.name}>
            {`${contact.city} -`}
          </div>
          <div className={styles.description}>
            {contact.street}
          </div>
        </div>
      ))}
      <div className={styles.contact}>
        <div className={styles.arrow} />
        <div className={styles.name}>
          м. Рівне
        </div>
        <div className={styles.description}>
          вул. Олега Дудєя, 60
        </div>
      </div>
      {
        phones && phones.map(phone => (
          <a
            href={`tel:${phone.number}`}
            key={phone.number}
            className={styles.contact}
          >
            <div className={styles.arrow} />
            <div className={styles.name}>
              {phone.name}
            </div>
            <div className={styles.number}>
              {phone.number}
            </div>
          </a>
        ))}
    </div>
  </div>
);

Contacts.propTypes = {
  contacts: PropTypes.array.isRequired,
  phones: PropTypes.array.isRequired
};

export default Contacts;
