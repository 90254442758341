import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Product from './components/product';
import Slider from '../slider';
import Item from '../slider/item';

class Products extends Component {
  static propTypes = {
    products: PropTypes.array,
    addProductToCart: PropTypes.func.isRequired,
    buy: PropTypes.func.isRequired,
  };

  static defaultProps = {
    products: undefined
  };

  render() {
    const { products, addProductToCart, buy } = this.props;
    if (!products) { return null; }

    const newProd =
      products
        .filter(product => !product.modifications.every(mod => mod && mod.images.length === 0));

    return (
      <div>
        <Slider>
          {newProd.map(product => (
            <Item key={product.id}>
              <Product
                product={product}
                addProductToCart={addProductToCart}
                buy={buy}
              />
            </Item>
          ))}
        </Slider>
      </div>
    );
  }
}

export default Products;
