import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import labels from '../../common/labels/index';
import styles from './styles.css';

class Callback extends Component {
  static propTypes = {
    saveCallback: PropTypes.func.isRequired,
    className: PropTypes.string,
    classNameWrap: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  state = {
    isInputShown: false,
    value: '',
    notification: {
      message: 'test',
      isShown: false,
      className: 'success'
    }
  };

  onSubmit = (ev) => {
    ev.preventDefault();
    this.props.saveCallback({ variables: { phone: this.state.value } })
      .then(() => {
        this.setState({
          notification: {
            message: labels.callbackSaveSuccess,
            isShown: true,
            className: 'success'
          }
        });
      })
      .catch(() => {
        this.setState({
          notification: {
            message: labels.callbackSaveFailure,
            isShown: true,
            className: 'error'
          }
        });
      })
      .then(() => {
        setTimeout(() => {
          this.setState({
            isInputShown: false,
            notification: { isShown: false }
          });
        }, 5000);
      });
  };

  onChange = (ev) => {
    this.setState({ value: ev.target.value });
  };

  showInput = () => {
    this.setState({ isInputShown: true });
    this.input.focus();
  };

  render() {
    return (
      <div className={`${styles.container} ${this.props.classNameWrap}`}>
        <div className={`${styles.wrap} ${this.props.className}`}>
          <button onClick={this.showInput} className={`${styles.button} ${this.state.isInputShown && styles.hidden}`}>
            <span className={styles.buttonText}>Зворотній дзвінок</span>
            <i className={styles.icon} />
          </button>
          <form onSubmit={this.onSubmit} className={styles.form}>
            <InputMask
              className={styles.input}
              mask="+380 (99) 999 99 99"
              maskChar=" "
              value={this.state.value}
              onChange={this.onChange}
              inputRef={(input) => { this.input = input; }}
            />
            <button type='submit' className={`${styles.icon} ${styles.iconForm}`} />
          </form>
        </div>
        <div
          className={`
            ${styles.notification}
            ${this.state.notification.className ? styles[this.state.notification.className] : ''}
            ${this.state.notification.isShown ? styles.visible : ''}
          `}
        >{this.state.notification.message}</div>
      </div>
    );
  }
}

export default Callback;
