import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import PageWithFilter from '../pageWithFilter';
import styles from './styles.css';

// const manufacurersMock = {
//   id: '5a8982f836884b4eb40eecbb',
//   type: 'select2',
//   name: 'Производитель',
//   values: [],
// };

const Search = (
  {
    products,
    match,
    location,
    history,
    addProductToCart,
    // manufacturers,
    buy,
  }) => {
  const parsedSearch = queryString.parse(location.search);
  const searchString =
    parsedSearch.search && parsedSearch.search.length !== 0
      ? parsedSearch.search
      : null;

  if (!products || !searchString) { return null; }
  //
  // const attributesForFilterPanel = [
  //   { ...manufacurersMock, values: manufacturers },
  // ];

  const textNotFound = `По запросу "${searchString}" ничего не найдено, перейти на главную страницу.`;
  const textSuccess = `По запросу "${searchString}" найдено: ${products.length} товаров`;
  const pageTitle = products.length === 0
    ? (
      <Link className={styles.link} to='/'>
        {textNotFound}
      </Link>)
    : textSuccess.toUpperCase();

  return (
    <div>
      <h1 className={cx('container', styles.title)}>
        {pageTitle}
      </h1>

      {products.length !== 0 && <PageWithFilter
        match={match}
        location={location}
        history={history}
        addProductToCart={addProductToCart}
        // attributesForFilterPanel={attributesForFilterPanel}
        products={products}
        buy={buy}
        metaTags={{
          metaDescription: '',
          metaTitle: '',
          canonical: `${window.location.origin}${location.pathname}`,
        }}
        footerText={''}
      />}
    </div>
  );
};

Search.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  // manufacturers: PropTypes.array.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  buy: PropTypes.func.isRequired
};

export default Search;
