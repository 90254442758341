import React from 'react';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';

import styles from './styles.css';
import ColorValue from './components/ColorValue';

const ColorAttributes = ({ attribute, slidesToShow, match, location, onClick }) => {
  const attributes = uniqBy(attribute.productValues, value => value.value.value);
  return (
    <div className={styles['color-attribute']} key={attribute.id}>
      <div className={styles['attribute-name']}>
        {attribute.name}
      </div>
      <CarouselProvider
        naturalSlideWidth={100}
        visibleSlides={slidesToShow}
        totalSlides={attributes.length}
        lockOnWindowScroll={true}
      >
        <Slider>
          {attributes.map(value => (
            <Slide key={value.id} className={styles['color-wrap']}>
              <div>
                <ColorValue
                  key={value.id}
                  value={value}
                  attributeId={attribute.id}
                  match={match}
                  location={location}
                  onClick={onClick}
                />
              </div>
            </Slide>
          ))}
        </Slider>
        <ButtonBack className='control-arrow control-prev' />
        <ButtonNext className='control-arrow control-next' />
      </CarouselProvider>
    </div>
  );
};

ColorAttributes.propTypes = {
  attribute: PropTypes.object.isRequired,
  slidesToShow: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ColorAttributes;
