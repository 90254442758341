import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { Carousel } from '../../../../common/react-responsive-carousel';
import ColorAttributes from '../colorAttributes';
import Markers from '../../../../components/markers';
import styles from './styles.css';

class ImagesCarousel extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    modifications: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    slidesToShow: PropTypes.number.isRequired,
    brand: PropTypes.string.isRequired,
  };

  state = {
    search: {}
  };

  onClick = (attributeId, value) => {
    this.setState({ search: { [attributeId]: value } });
  };

  getColorAttributes = product => (
    product.attributes.filter((attribute) => {
      if (!attribute) { return false; }
      if (attribute.type === 'color') { return attribute; }
      return false;
    }));

  getModificationsImages = (product, location) => {
    if (!location.search) {
      return this.joinImages(product.productModifications);
    }
    return this.joinImages(product.productModifications, location);
  };

  joinImages = () => {
    const { modifications } = this.props;
    const filtered = modifications;
    if (filtered.length === 0) {
      return [];
    }

    return filtered.map(mods => mods.images)
      .reduce((a, b) => a.concat(b));
  };

  render() {
    const {
      match,
      location,
      slidesToShow,
      product,
      brand,
    } = this.props;
    const { search } = this.state;
    const colorAttributes = this.getColorAttributes(product);
    const images = this.getModificationsImages(product, location);
    if (images.length === 0) {
      return (
        <Redirect
          to={{
            pathname: match.url,
            search: queryString.stringify(search)
          }}
        />
      );
    }
    const price = Math.round(product.price);

    const salePrice = Math.round(product.salePrice) || '';

    return (
      <div className={`${styles.carousel} col-xs-12 col-sm-5 col-md-4`}>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'Product',
              name: product.name,
              image: images,
              description: product.description,
              price,
              priceCurrency: 'UAH',
              availability: product.active ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
              url: product.canonical,
              brand: {
                '@type': 'Brand',
                name: brand,
              },
              offers: salePrice ? {
                '@type': 'Offer',
                url: 'https://example.com/anvil',
                priceCurrency: 'UAH',
                price: salePrice,
              } : null,
            })}
          </script>
        </Helmet>
        <Carousel
          showArrows={true}
          axis='horizontal'
          showIndicators={false}
          showStatus={false}
          showThumbs={true}
          swipeScrollTolerance={50}
        >
          {images && images.map(image => (
            <div className={styles['image-wrap']} key={image.path}>
              <Markers
                markerClass={styles.marker}
                markersClass={styles.markers}
                product={product}
              />
              <img property="image" className={styles.image} alt="alt" src={image.path} />
            </div>
          ))}
        </Carousel>
        {colorAttributes.map(attribute => (
          <ColorAttributes
            key={attribute.id}
            onClick={this.onClick}
            attribute={attribute}
            slidesToShow={slidesToShow}
            match={match}
            location={location}
          />
        ))}
      </div>
    );
  }
}

export default ImagesCarousel;
