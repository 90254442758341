import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import productsQuery from './productsQuery.graphql';
import manufacturersQuery from './manufacturersQuery.graphql';
import component from './component';
import { compose } from '../../common/utils';

export default withRouter((compose(
  graphql(productsQuery, {
    options: ({ location }) => {
      const parsedSearch = queryString.parse(location.search);
      const query =
        parsedSearch.search && parsedSearch.search.length !== 0
          ? parsedSearch.search
          : null;
      return { variables: { queryString: query } };
    },
    props: ({ data: { products = [] } }) => ({ products })
  }),
  graphql(manufacturersQuery, {
    props: ({ data: { manufacturers = [] } }) => ({ manufacturers })
  }),
)
)(component));
