import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import SectionH1 from '../../components/sectionH1';
import ManufacturersList from '../../components/manufacturersList';
import styles from './styles.css';

const Manufacturers = ({ manufacturers, match }) => (
  <div className={`${styles.wrap} container`}>
    <Helmet>
      <meta name="robots" content="index,follow" />
      <meta charSet="utf-8" />
      <title>Бренды</title>
      <meta name='description' content="Все бренды в интернет магазине Хорошка" />
      <link rel="canonical" href="https://horoshka.com.ua/manufacturers" />
    </Helmet>
    <SectionH1 name={'БРЕНДИ'} />
    <ManufacturersList manufacturers={manufacturers} match={match} />
  </div>
);

Manufacturers.propTypes = {
  manufacturers: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
};

export default Manufacturers;
