import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import queryString from 'query-string';
import styles from './styles.css';

class Search extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super();
    const { location } = props;
    const parsedSearch = queryString.parse(location.search);
    if (parsedSearch && parsedSearch.search) {
      this.state = { search: parsedSearch.search };
    } else {
      this.state = { search: '' };
    }
  }

  onChange = (ev) => {
    this.setState({ search: ev.target.value });
  };

  onSubmit = (ev) => {
    const { history, location } = this.props;
    const parsedSearch = queryString.parse(location.search);
    ev.preventDefault();
    parsedSearch.search = this.state.search;
    if (this.state.search.length === 0) { return; }
    history.push(`/search/?${queryString.stringify(parsedSearch)}`);
  };

  render() {
    const { search } = this.state;
    const { className } = this.props;
    return (
      <div className={cx('col-xs-12 col-sm-5', className, styles.wrapper)}>
        <form onSubmit={this.onSubmit} role='search' className={cx(styles.wrap)}>
          <input
            placeholder='Пошук'
            className={cx(styles.search)}
            type="search" value={search}
            onChange={this.onChange}
            name="search"
          />
          <button className={styles.button} type="submit">
            Шукати
          </button>
        </form>
      </div>
    );
  }
}

export default Search;
