import React from 'react';
import PropTypes from 'prop-types';
import CartProduct from '../cartProduct';
import styles from './styles.css';

const CartProducts = ({ cart, amountOnChange, removeProduct }) => (
  <div className={styles.wrapper}>
    {cart.map(product => (
      <CartProduct
        key={product.id}
        product={product}
        amountOnChange={amountOnChange}
        removeProduct={removeProduct}
      />
    ))}
  </div>
);

CartProducts.propTypes = {
  cart: PropTypes.array.isRequired,
  amountOnChange: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired
};

export default CartProducts;
