import React, { useCallback, useState, createRef } from 'react';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import styles from './styles.css';

const Slider = ({ children }) => {
  const [transform, setTransform] = useState(0);
  const [startPoint, setStartPoint] = useState({ clientX: 0, clientY: 0 });
  const [currentPoint, setCurrentPoint] = useState({ clientX: 0, clientY: 0 });
  const wrapRef = createRef();
  const cardWidth = 275;
  const padding = 30;
  const width = children.length * (cardWidth + padding);

  const onTouchStart = useCallback((ev) => {
    const { clientX, clientY } = ev.touches[0];
    setStartPoint({ clientX, clientY });
  }, [startPoint.clientX, startPoint.clientY]);

  const onClickPrev = useCallback(() => {
    const newTransform = transform + (cardWidth + padding);
    if (newTransform > -305) {
      setTransform(0);
      return;
    }
    setTransform(newTransform);
  }, [transform]);

  const onClickNext = useCallback(() => {
    const newTransform = transform - (cardWidth + padding);
    const wrapWidth = wrapRef.current && wrapRef.current.clientWidth;
    if (newTransform < -(width - wrapWidth)) {
      setTransform(-(width - wrapWidth));
      return;
    }
    setTransform(newTransform);
  }, [transform, wrapRef]);

  const onTouchEnd = useCallback(() => {
    if (!currentPoint.clientX) return;

    const diff = startPoint.clientX - currentPoint.clientX;
    if (Math.abs(diff) < 10) return;

    if (diff < 0) {
      onClickPrev();
      setCurrentPoint({ clientX: 0, clientY: 0 });
      return;
    }
    onClickNext();
    setCurrentPoint({ clientX: 0, clientY: 0 });
  }, [startPoint.clientX, currentPoint.clientX]);

  const translateOnMove = throttle(useCallback((touch) => {
    const { clientX, clientY } = touch;
    if (Math.abs(touch.clientY - startPoint.clientY) < 100) {
      setCurrentPoint({ clientX, clientY });
    }
  }, [startPoint.clientY, startPoint.clientX]), 100);

  const onTouchMove = useCallback((ev) => {
    translateOnMove(ev.touches[0]);
  }, [startPoint.clientY, currentPoint.clientY]);

  return (
    <div className={styles.wrapper}>
      <div
        ref={wrapRef}
        className={styles.wrap}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
      >
        <div
          className={styles.slider}
          style={{
            width: `${width}px`,
            transform: `translateX(${transform}px)`,
            transition: '200ms ease-out'
          }}
        >
          {children}
        </div>
      </div>
      <button
        className={`${styles.arrow} ${styles.prev} ${transform === 0 && styles.disabled}`}
        onClick={onClickPrev}
        disabled={transform === 0}
      />
      <button
        onClick={onClickNext}
        className={`${styles.arrow} ${styles.next}`}
      />
    </div>
  );
};

Slider.propTypes = {
  children: PropTypes.node.isRequired,
};


export default Slider;
