import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

const SectionH1 = ({ name, className }) => (
  <div className={`${styles['title-wrap']} ${className}`}>
    <h1 className={styles.title}>
      {name}
    </h1>
    <div className={styles.border} />
  </div>
);

SectionH1.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string
};

SectionH1.defaultProps = {
  name: '',
  className: ''
};

export default SectionH1;
