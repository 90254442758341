import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

const FromToInput = ({ attribute, onChangeFrom, onChangeTo, min, max }) => (
  <div className={styles.wrap}>
    <input
      className={styles.input}
      type="number"
      value={min}
      placeholder="от"
      onChange={ev => onChangeFrom(ev, attribute)}
    />
    <input
      className={styles.input}
      type="number"
      value={max}
      placeholder="до"
      onChange={ev => onChangeTo(ev, attribute)}
    />
  </div>
);

FromToInput.propTypes = {
  attribute: PropTypes.object,
  onChangeFrom: PropTypes.func.isRequired,
  onChangeTo: PropTypes.func.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
};

FromToInput.defaultProps = {
  attribute: {},
  values: [],
  min: undefined,
  max: undefined
};

export default FromToInput;
