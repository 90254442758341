import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import styles from './styles.css';

class RangeInput extends Component {
  static propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    label: PropTypes.string,
    // values: PropTypes.array.isRequired,
    // getMaxValue: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onChangeComplete: PropTypes.func.isRequired,
  };

  static defaultProps = {
    attribute: {},
    label: '',
    values: [],
  };

  render() {
    const { label, onChangeValue, min, max, step, maxValue } = this.props;
    const valueCurrent = {
      min,
      max
    };

    return (
      <div className={styles.range}>
        <div className={styles.labels}>
          <div>
            {`от ${min} ${label}`}
          </div>
          <div>
            {`до ${max} ${label}`}
          </div>
        </div>
        <InputRange
          maxValue={maxValue + step}
          step={step}
          minValue={0}
          formatLabel={value => `${value} ${label}`}
          value={valueCurrent || 1}
          onChangeComplete={this.props.onChangeComplete}
          onChange={onChangeValue}
        />
      </div>
    );
  }
}

export default RangeInput;
