import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styles from './styles.css';

const CategoriesList = ({ categories = [] }) => (
  <div className={`${styles.categories} col-md-8 col-lg-8`}>
    <NavLink
      to='/categories'
      className={styles.catalog}
      activeClassName={styles['active-catalog']}
    >
      <div className={`${styles.arrow} ${styles['left-catalog']}`} />
      КАТАЛОГ
      <div className={`${styles.arrow} ${styles['right-catalog']}`} />
    </NavLink>
    {categories.map(category => (
      <NavLink
        to={`/${category.href}`}
        className={styles.category}
        key={category.id}
        activeClassName={styles.active}
      >
        <div className={`${styles.arrow} ${styles.left}`} />
        {category.name}
        <div className={`${styles.arrow} ${styles.right}`} />
      </NavLink>
    ))}
    <NavLink
      to={'/skidki'}
      className={styles.category}
      key={'skidki'}
      activeClassName={styles.active}
    >
      <div className={`${styles.arrow} ${styles.left}`} />
      {'знижки'}
      <div className={`${styles.arrow} ${styles.right}`} />
    </NavLink>
    <NavLink
      to={'/manufacturers'}
      className={styles.category}
      key={'manufacturers'}
      activeClassName={styles.active}
    >
      <div className={`${styles.arrow} ${styles.left}`} />
      {'бренди'}
      <div className={`${styles.arrow} ${styles.right}`} />
    </NavLink>
  </div>
);

CategoriesList.propTypes = {
  categories: PropTypes.array.isRequired
};

export default CategoriesList;
