import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.css';

const SubmitCart = ({ name, className }) => (
  <button className={cx(styles.button, className)} type='submit'>{name}</button>
);

SubmitCart.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

SubmitCart.defaultProps = {
  className: ''
};

export default SubmitCart;
