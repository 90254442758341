import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter, Switch } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';

import { root } from '../../styles.scss';

import Home from '../../pages/home/index';
import Category from '../../pages/category';
import Header from '../header/index';
import CartSuccess from '../cartSuccess';
import CallbackFormSuccess from '../callbackFormSuccess';
import Delivery from '../../pages/delivery';
import Footer from '../footer';
import HowToOrder from '../../pages/howToOrder';
import Guarantee from '../../pages/guarantee';
import ProductPage from '../../pages/productPage';
import Article from '../../pages/article';
import Contacts from '../../pages/contacts';
import Categories from '../../pages/categories';
import Cart from '../../pages/cart';
import Manufacturers from '../../pages/manufacturers';
import Manufacturer from '../../pages/manufacturer';
import CallbackForm from '../callbackForm';
import SaleProducts from '../../pages/saleProducts';
import Search from '../../pages/search';
import PageNotFound from '../../pages/404';
import LiqPay from '../../pages/liqpay';

class App extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    staticContext: PropTypes.object,
    categories: PropTypes.array.isRequired,
    homePageText: PropTypes.string.isRequired,
  };

  static defaultProps = {
    staticContext: {},
  };

  constructor() {
    super();
    const cart = localStorage.getItem('cart');

    if (!cart) {
      this.state = {
        cart: [],
      };
    } else {
      const cartArr = JSON.parse(cart);
      this.state = {
        cart: cartArr,
      };
    }
  }

  addProductToCart = (id) => {
    const { cart } = this.state;
    const index = cart.findIndex(product => product.id === id);
    if (index === -1) {
      const product = { id, amount: 1 };

      this.setState({ cart: [...cart, product] });
      localStorage.setItem('cart', JSON.stringify([...cart, product]));
    } else {
      this.changeProductAmount(id, cart[index].amount + 1);
    }

    toast.success('Товар добавлен в корзину', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  };

  buy = (id) => {
    this.addProductToCart(id);
    this.props.history.push('/cart');
  };

  deleteProductFromCart = (id) => {
    const { cart } = this.state;
    const index = cart.findIndex(product => product.id === id);
    const newCart = [...cart.slice(0, index), ...cart.slice(index + 1)];
    this.setState({ cart: newCart });
    localStorage.setItem('cart', JSON.stringify(newCart));
  };

  changeProductAmount = (id, amount) => {
    const { cart } = this.state;
    let modifiedAmount = amount;
    if (amount < 1) { modifiedAmount = 1; }
    const index = cart.findIndex(product => product.id === id);
    const product = {
      ...cart[index],
      amount: modifiedAmount
    };
    const before = cart.slice(0, index);
    const after = cart.slice(index + 1);
    const newCart = [...before, product, ...after];
    this.setState({ cart: newCart });
    localStorage.setItem('cart', JSON.stringify(newCart));
  };

  render() {
    const { cart } = this.state;
    const categoriesRoutes = this.props.categories.map(category => category.href);
    const staticCategories = this.props.staticContext && this.props.staticContext.preloadCategories;
    let reducedCategoriesHrefs;
    if (staticCategories) {
      const staticCategoriesRoutes = staticCategories.map(category => category.href);
      reducedCategoriesHrefs = staticCategoriesRoutes.reduce((a, b) => `${a}|${b}`, '');
    } else {
      reducedCategoriesHrefs = categoriesRoutes.length !== 0 && categoriesRoutes.reduce((a, b) => `${a}|${b}`);
    }

    return (
      <div className={`${root}`}>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          pauseOnHover
        />
        <Header {...this.props} />
        <Switch>
          <Route
            exact path='/'
            render={() =>
              <Home
                addProductToCart={this.addProductToCart}
                buy={this.buy}
                pageText={this.props.homePageText}
              />
            }
          />
          <Route path='/categories' component={Categories} />

          <Route
            path='/manufacturers/:href'
            render={() =>
              <Manufacturer
                addProductToCart={this.addProductToCart}
                buy={this.buy}
              />
            }
          />
          <Route
            exact
            path='/manufacturers'
            component={Manufacturers}
          />
          <Route
            exact
            path='/feedback'
            component={CallbackForm}
          />
          <Route
            path='/product/:href'
            render={() =>
              <ProductPage
                addProductToCart={this.addProductToCart}
                buy={this.buy}
              />
            }
          />
          <Route path='/articles/:href' component={Article} />
          <Route path='/contacts' render={() => <Contacts {...this.props} />} />
          <Route path='/how-to-order' render={() => <HowToOrder {...this.props} />} />
          <Route path='/guarantee' render={() => <Guarantee {...this.props} />} />
          <Route path='/delivery' render={() => <Delivery {...this.props} />} />
          <Route
            path='/skidki'
            render={() => (
              <SaleProducts
                {...this.props}
                addProductToCart={this.addProductToCart}
                buy={this.buy}
              />
            )}
          />
          <Route
            path='/search'
            render={() => (
              <Search
                {...this.props}
                addProductToCart={this.addProductToCart}
                buy={this.buy}
              />
            )}
          />
          <Route
            path='/cart'
            exact
            render={() =>
              <Cart
                cart={cart}
                deleteProductFromCart={this.deleteProductFromCart}
                changeProductAmount={this.changeProductAmount}
              />
            }
          />
          <Route exact path='/cart/success' component={CartSuccess} />
          <Route exact path='/cart/liqpay' component={LiqPay} />
          <Route exact path='/callback-form/success' component={CallbackFormSuccess} />
          <Route
            path={`/:href(${reducedCategoriesHrefs})`}
            render={() =>
              <Category
                addProductToCart={this.addProductToCart}
                {...this.props}
                buy={this.buy}
              />
            }
          />
          <Route component={PageNotFound} />
        </Switch>
        <Footer {...this.props} />
      </div>
    );
  }
}

export default withRouter(App);
