import React from 'react';
import PropTypes from 'prop-types';
import ToggleItem from '../toggleItem';
import styles from './styles.css';

const ToggleList = ({ list, footer }) => (
  <div>
    {list.map((item, index) => (
      <ToggleItem item={item} key={index} />
    ))}
    <div className={styles.description} dangerouslySetInnerHTML={{ __html: footer }} />
  </div>
);

ToggleList.propTypes = {
  list: PropTypes.array.isRequired,
  footer: PropTypes.string
};

ToggleList.defaultProps = {
  footer: ''
};

export default ToggleList;
