import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import labels from '../../common/labels';
import SectionH1 from '../../components/sectionH1';
import ToggleList from '../../components/toggleList';
import styles from './styles.css';

const Delivery = ({ info }) => {
  const text = info.links[1] && info.links[1].text;
  return (
    <div className={`${styles.container} container`}>
      <Helmet>
        <meta name="robots" content="index,follow" />
        <meta charSet="utf-8" />
        <meta name='description' content="Умови доставки та способи оплати від магазину дитячих товарів. Вартість міжнародної доставки та доставки по Україні" />
        <title>Умови доставки та оплати на horoshka.com.ua</title>
        <link rel="canonical" href="https://horoshka.com.ua/delivery" />
      </Helmet>
      <SectionH1 name={labels['delivery-title'].toUpperCase()} className={styles.title} />
      {text ? <div dangerouslySetInnerHTML={{ __html: text }} /> : (
        <ToggleList list={labels['delivery-items']} footer={labels['delivery-footer']} />
      )}
    </div>
  );
};

Delivery.propTypes = {
  info: PropTypes.object.isRequired
};


export default Delivery;
