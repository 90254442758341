import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import sortBy from 'lodash/sortBy';
import styles from './styles.css';
import Numeric from '../numeric';

class Numerics extends Component {
  static propTypes = {
    attribute: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  onChangeTo = ({ value, attributeId }) => {
    const { match, history, location } = this.props;
    const parseSearch = queryString.parse(location.search);
    return history.push({
      path: match,
      search: queryString.stringify({
        ...parseSearch,
        [`${attributeId}-to`]: Number(value),
      })
    });
  };

  onChangeFrom = ({ value, attributeId }) => {
    const { match, history, location } = this.props;
    const parseSearch = queryString.parse(location.search);
    return history.push({
      path: match,
      search: queryString.stringify({
        ...parseSearch,
        [`${attributeId}-from`]: Number(value),
      })
    });
  };

  onChangeComplete = (value) => {
    const { history, match, attribute, location } = this.props;
    const parseSearch = queryString.parse(location.search);
    history.push({
      path: match,
      search: queryString.stringify({
        ...parseSearch,
        [`${attribute.id}-from`]: value.min,
        [`${attribute.id}-to`]: value.max,
      })
    });
  };

  getMaxValue = (values) => {
    if (!values || values.length === 0) { return 0; }
    const sorted = sortBy(values, [item => Number(item.value.value)]);
    const length = sorted.length;
    return sorted[length - 1].value.value;
  };

  render() {
    const { attribute, match, location, history } = this.props;
    const parseSearch = queryString.parse(location.search);
    return (
      <div>
        {(attribute.type === 'number') &&
          <div className={styles.title}>
            {attribute.name}
          </div>
        }
        <div className={styles.wrap}>
          {attribute.type === 'number' &&
            <Numeric
              attribute={attribute}
              match={match}
              location={location}
              history={history}
              onChangeFrom={this.onChangeFrom}
              onChangeTo={this.onChangeTo}
              onChangeValue={this.onChangeComplete}
              min={parseSearch[`${attribute.id}-from`] && Number(parseSearch[`${attribute.id}-from`])}
              max={parseSearch[`${attribute.id}-to`] && Number(parseSearch[`${attribute.id}-to`])}
              getMaxValue={this.getMaxValue}
              values={attribute.values}
              label={attribute.units}
            />
          }
        </div>
      </div>
    );
  }
}

export default Numerics;
