import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/sectionTitle';
import styles from './styles.css';

const Categories = ({ categories = [] }) => (
  <div className='container'>
    <Helmet>
      <meta name="robots" content="index,follow" />
      <meta charSet="utf-8" />
      <meta name='description' content="" />
      <title>Каталог товарів</title>
      <link rel="canonical" href="" />
    </Helmet>
    <SectionTitle name='КАТАЛОГ' />
    <div className={`row ${styles.categories}`}>
      {categories.map(category =>
        <div key={category.id} className="col-xs-12 col-sm-6 col-md-4">
          <Link
            className={styles.category}
            to={`/${category.href}`}
            style={{ backgroundImage: `url(${category.image && category.image.path})` }}
          >
            <h4 className={styles.title}>{category.name}</h4>
            <p className={styles.description}>{category.description}</p>
          </Link>
        </div>
      )}
      <div key='skidki' className="col-xs-12 col-sm-6 col-md-4">
        <Link
          className={styles.category}
          to='/skidki'
          style={{ backgroundImage: 'url("/images/skidki.jpg")' }}
        >
          <h4 className={styles.title}>Знижки</h4>
          <p className={styles.description}>Знижки та акції</p>
        </Link>
      </div>
    </div>
  </div>
);

Categories.propTypes = {
  categories: PropTypes.array.isRequired
};

export default Categories;
