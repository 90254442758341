import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.css';

const Markers = ({ product = {}, markerClass, markersClass }) => (
  <div className={cx(styles.markers, markersClass)}>
    {
      product.sale &&
      <div className={cx(styles.marker, markerClass, styles['sale-marker'])}>
        АКЦІЯ
      </div>
    }
    {
      product.latest &&
      <div className={cx(styles.marker, markerClass, styles['new-marker'])}>
        НОВИНКА
      </div>
    }
    {
      product.popular &&
      <div className={cx(styles.marker, markerClass, styles['popular-marker'])}>
        ПОПУЛЯРНЕ
      </div>
    }
    {
      product.freeDelivery &&
      <div className={cx(styles.marker, markerClass, styles['free-delivery'])}>
        БЕЗКОШТОВНА ДОСТАВКА
      </div>
    }
  </div>
);

Markers.propTypes = {
  product: PropTypes.object,
  markerClass: PropTypes.string,
  markersClass: PropTypes.string
};

Markers.defaultProps = {
  product: {},
  markerClass: '',
  markersClass: ''
};

export default Markers;
