import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles.css';

const Article = ({ article }) => (
  <div className={styles.wrap}>
    <Link to={`/articles/${article.href}`} className={styles.article}>
      <div
        className={styles.img}
        style={{ backgroundImage: `url("${article.image && article.image.path}")` }}
      />
      <div className={styles.content}>
        <div className={styles.title}>
          {article.title}
        </div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: article.shortDescription }}
        />
      </div>

      <button className={styles.read}>
        ЧИТАТИ
      </button>
    </Link>
  </div>
);

Article.propTypes = {
  article: PropTypes.object.isRequired
};

export default Article;
