import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import FromToInput from '../../../inputs/fromToInput';
import RangeInput from '../../../inputs/rangeInput';
import styles from './styles.css';

class Numeric extends Component {
  static propTypes = {
    attribute: PropTypes.object,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onChangeFrom: PropTypes.func.isRequired,
    onChangeTo: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    label: PropTypes.string,
    values: PropTypes.array,
    getMaxValue: PropTypes.func.isRequired
  };

  static defaultProps = {
    attribute: {},
    label: '',
    values: [],
    min: 0,
    max: undefined
  };

  constructor(props) {
    super(props);
    const { step, maxValue } = this.getMaxPossibleValueAndStep(props);

    this.state = {
      min: props.min || 0,
      max: props.max || maxValue,
      step,
      maxValue,
    };
  }

  onChange = ({ min, max }) => {
    this.setState({ min, max });
  };

  onChangeFrom = (ev) => {
    const value = ev.target && ev.target.value;
    this.setState({ min: value });
    const throttledOnChange = debounce(this.props.onChangeFrom, 2000);
    throttledOnChange({ value, attributeId: this.props.attribute.id });
  };

  onChangeTo = (ev) => {
    const value = ev.target && ev.target.value;
    this.setState({ max: value });
    const throttledOnChange = debounce(this.props.onChangeTo, 2000);
    throttledOnChange({ value, attributeId: this.props.attribute.id });
  };

  getMaxPossibleValueAndStep = (props) => {
    const { getMaxValue, values } = props;
    const maxValue = Number(getMaxValue(values));
    let step;
    if (maxValue < 50) {
      step = 1;
    } else if (maxValue >= 100 && maxValue < 10000) {
      step = 10;
    } else if (maxValue >= 10000) {
      step = 100;
    }
    return {
      step,
      maxValue
    };
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      const { maxValue, step } = this.getMaxPossibleValueAndStep(nextProps);
      this.setState({
        maxValue,
        step,
        max: nextProps.max ? nextProps.max : maxValue,
        min: nextProps.min ? nextProps.min : 0
      });
    }
  }

  render() {
    const {
      attribute,
      match,
      location,
      history,
      onChangeValue,
      values,
      label
    } = this.props;
    const { min, max, maxValue, step } = this.state;
    return (
      <div className={styles.wrap}>
        <FromToInput
          attribute={attribute}
          match={match}
          location={location}
          history={history}
          onChangeFrom={this.onChangeFrom}
          onChangeTo={this.onChangeTo}
          min={min}
          max={max}
        />
        <RangeInput
          attribute={attribute}
          match={match}
          location={location}
          history={history}
          onChangeValue={this.onChange}
          onChangeComplete={onChangeValue}
          label={label}
          values={values}
          maxValue={maxValue}
          step={step}
          min={min}
          max={max}
        />
      </div>
    );
  }
}

export default Numeric;
