import React from 'react';
import PropTypes from 'prop-types';
import PageWithFilter from '../pageWithFilter';

const SaleProducts = (
  {
    saleProducts,
    match,
    location,
    history,
    addProductToCart,
    buy,
  }) => (
    <PageWithFilter
      match={match}
      location={location}
      history={history}
      addProductToCart={addProductToCart}
      products={saleProducts}
      pageTitle={'скидки'}
      buy={buy}
      metaTags={{
        metaDescription: 'Акции и скидки, товары с бесплатной доставкой и акционной гарантией в интернет магазине Хорошка',
        metaTitle: 'Скидки и акции на товары в интернет магазине Хорошка',
        canonical: `${window.location.origin}${location.path}`,
      }}
      footerText={''}
    />
  );

SaleProducts.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  saleProducts: PropTypes.array.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  buy: PropTypes.func.isRequired
};

export default SaleProducts;
