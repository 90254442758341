import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './styles.css';

const ManufacturersList = ({ manufacturers, match }) => (
  <div className={styles.wrap}>
    {manufacturers.map(manufacturer => (
      <Link
        key={manufacturer.id}
        className={styles.link}
        to={`${match.path}/${manufacturer.href}`}
      >
        {manufacturer.value}
      </Link>
    ))}
  </div>
);

ManufacturersList.propTypes = {
  manufacturers: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired
};

export default ManufacturersList;
