import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

const Contacts = ({ contacts, phones, className, contactClassName }) => (
  <div className={`${styles.wrap} ${className}`}>
    {contacts && contacts.map(contact => (
      <div
        key={contact.id}
        className={`${styles.contact} ${contactClassName}`}
      >
        <div className={styles.name}>
          {contact.city}
        </div>
        <div className={styles.description}>
          {contact.street}
        </div>
      </div>
    ))}
    <div
      className={`${styles.contact} ${contactClassName}`}
    >
      <div className={styles.name}>
        м. Рівне
      </div>
      <div className={styles.description}>
        вул. Олега Дудєя, 60
      </div>
    </div>
    {phones && phones.map(phone => (
      <a
        href={`tel:${phone.number}`}
        key={phone.number}
        className={`${styles.contact} ${contactClassName}`}
      >
        <div className={styles.name}>
          {phone.name}
        </div>
        <div className={`${styles.description} ${styles.number}`}>
          {phone.number}
        </div>
      </a>
    ))}
  </div>
);

Contacts.propTypes = {
  contacts: PropTypes.array.isRequired,
  phones: PropTypes.array.isRequired,
  className: PropTypes.string,
  contactClassName: PropTypes.string
};

Contacts.defaultProps = {
  className: '',
  contactClassName: ''
};


export default Contacts;
