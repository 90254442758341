import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import Checkbox from '../checkbox';
import styles from './styles.css';

const Checkboxes = ({ attribute, match, location }) => {
  const values = attribute.type === 'select'
    ? sortBy(attribute.values, [value => value.value.value])
    : sortBy(attribute.values, [value => value.value]);
  return (
    <div className={styles.wrap}>
      <div className={styles.title}>
        {attribute.name}
      </div>
      <div className={styles.list}>
        {values.map(item => (
          attribute.type === 'select'
          ?
            <Checkbox
              id={item.id}
              attribute={attribute}
              key={item.id}
              value={item.value.value}
              label={item.value.value}
              match={match}
              location={location}
            />
          :
            <Checkbox
              id={item.id}
              attribute={attribute}
              key={item.id}
              value={item.value}
              label={item.value}
              match={match}
              location={location}
            />
        ))}
      </div>
    </div>
  );
};

Checkboxes.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  attribute: PropTypes.object.isRequired,
};

export default Checkboxes;
