import React from 'react';
import PropTypes from 'prop-types';
import BreadCrumb from './components/breadCrumb';
import styles from './styles.css';

const BreadCrumbs = ({ breadCrumbs, className }) => {
  if (!breadCrumbs) { return null; }

  return (
    <div className={`${styles.wrap} ${className}`}>
      {
        breadCrumbs.map(breadCrumb => (
          <BreadCrumb {...breadCrumb} />
        ))
      }
    </div>
  );
};

BreadCrumbs.propTypes = {
  breadCrumbs: PropTypes.array,
  className: PropTypes.string,
};

BreadCrumbs.defaultProps = {
  breadCrumbs: [],
  className: '',
};


export default BreadCrumbs;
