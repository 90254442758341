import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

const CartSubtitle = ({ name }) => (
  <h6 className={styles.title}>{name}</h6>
);

CartSubtitle.propTypes = {
  name: PropTypes.string.isRequired
};

export default CartSubtitle;
