import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import SectionTitle from '../../components/sectionTitle';
import CartSubtitle from '../../components/cartSubtitle';
import SubmitCart from '../../components/submitCart';
import CartFormInput from '../../components/cartFormInput';
import CartFormRadio from '../../components/cartFormRadio';
import CartFormTextarea from '../../components/cartFormTextarea';
import CartProducts from '../../components/cartProducts';
import styles from './styles.css';

const paymentWarning = 'УВАГА! Перед оплатою онлайн на сайті необхідно дочекатися підтвердження наявності обраного товару від менеджера.';

class Cart extends Component {
  static propTypes = {
    cart: PropTypes.array.isRequired,
    saveOrder: PropTypes.func.isRequired,
    saveOrderWithLiqpay: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    deleteProductFromCart: PropTypes.func.isRequired,
    changeProductAmount: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        address: '',
        commentary: '',
        delivery: {
          options: [
            { id: 'delivery-courier', label: 'доставка кур\'єром', value: '1' },
            { id: 'delivery-post', label: 'Доставка кур\'єрськими службами Делівері, Нова Пошта', value: '2' },
            { id: 'delivery-self', label: 'Самовивіз', value: '3' },
          ],
          value: '1'
        },
        payment: {
          options: [
            { id: 'payment-cash', label: 'Оплата готівкою', value: '1' },
            { id: 'payment-card', label: 'Оплата онлайн (LiqPay)', value: '2' }
          ],
          value: '1'
        },
        cart: props.cart
      }
    };
  }

  onChange = (name, value) => {
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps === this.props) {
      return;
    }
    this.setState({
      form: {
        ...this.state.form,
        cart: nextProps.cart
      }
    });
  }

  deliveryOnChange = (ev) => {
    this.setState({
      form: {
        ...this.state.form,
        delivery: {
          ...this.state.form.delivery,
          value: ev.target.value
        }
      }
    });
  };

  paymentOnChange = (ev) => {
    this.setState({
      form: {
        ...this.state.form,
        payment: {
          ...this.state.form.payment,
          value: ev.target.value
        }
      }
    });
  };

  commentaryOnChange = (ev) => {
    this.setState({
      form: {
        ...this.state.form,
        commentary: ev.target.value
      }
    });
  };

  amountOnChange = (id, amount) => {
    let modifiedAmount = amount;
    if (amount < 1) {
      modifiedAmount = 1;
    }

    const form = {
      ...this.state.form,
      cart: this.state.form.cart.map((product) => {
        if (product.id !== id) {
          return product;
        }
        return {
          ...product,
          amount: modifiedAmount
        };
      })
    };
    this.props.changeProductAmount(id, amount);
    this.setState({ form });
  };

  removeProduct = (id) => {
    const form = {
      ...this.state.form,
      cart: this.state.form.cart.filter(product => product.id !== id)
    };

    this.setState({ form });
    this.props.deleteProductFromCart(id);
  };

  saveOrder(input) {
    this.props
      .saveOrder({ variables: { input } })
      .then(() => {
        localStorage.removeItem('cart');
        this.props.history.push('/cart/success');
      })
      .catch(err => console.log(err));
  }

  saveOrderWithLiqpay(input) {
    this.props
      .saveOrderWithLiqpay({ variables: { input } })
      .then(({ data: { saveOrderWithLiqpay } }) => {
        localStorage.removeItem('cart');

        this.props.history.push({
          pathname: '/cart/liqpay',
          search: queryString.stringify({
            data: saveOrderWithLiqpay.data,
            signature: saveOrderWithLiqpay.signature
          })
        });
      })
      .catch(err => console.log(err));
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    const input = {
      ...this.state.form,
      delivery: this.state.form.delivery.value,
      payment: this.state.form.payment.value,
      cart: this.state.form.cart.map(product => ({ id: product.id, amount: product.amount }))
    };

    switch (Number(input.payment)) {
      case 1:
        return this.saveOrder(input);
      case 2:
        return this.saveOrderWithLiqpay(input);
      default:
        return this.saveOrder(input);
    }
  };

  render() {
    const summary = this.state.form.cart.reduce(
      (sum, product) => sum + product.price * product.amount, 0
    );
    const roundSummary = Math.round(summary);
    return (
      <div className='container'>
        <Helmet>
          <meta name="robots" content="index,follow" />
          <meta charSet="utf-8" />
          <meta name='description' content="" />
          <title>ОФОРМЛЕННЯ ЗАМОВЛЕННЯ</title>
          <link rel="canonical" href="" />
        </Helmet>
        <SectionTitle name='ОФОРМЛЕННЯ ЗАМОВЛЕННЯ' />

        <form onSubmit={this.handleSubmit} className={`${styles.form} row`}>
          <div className="col-xs-12 col-sm-6">
            <CartSubtitle name='Особисті дані' />

            <CartFormInput
              name='name'
              label="Ім'я"
              id='cart-name'
              required={true}
              onChange={this.onChange}
              value={this.state.form.name}
            />
            <CartFormInput
              name='surname'
              label='Прізвище'
              id='cart-surname'
              required={true}
              onChange={this.onChange}
              value={this.state.form.surname}
            />
            <CartFormInput
              name='phone'
              label='Телефон'
              id='cart-phone'
              required={true}
              // mask="+380 (99) 999 99 99"
              minLength={10}
              onChange={this.onChange}
              value={this.state.form.phone}
            />
            <CartFormInput
              name='email'
              type='email'
              label='E-mail'
              id='cart-phone'
              onChange={this.onChange}
              value={this.state.form.email}
            />

            <CartSubtitle name='Спосіб доставки' />

            <CartFormRadio
              name='delivery'
              options={this.state.form.delivery.options}
              value={this.state.form.delivery.value}
              onChange={this.deliveryOnChange}
            />

            <CartSubtitle name='Варіанти оплати' />

            <CartFormRadio
              name='payment'
              options={this.state.form.payment.options}
              value={this.state.form.payment.value}
              onChange={this.paymentOnChange}
            />
            <div className={styles.warning}>{paymentWarning}</div>
            <CartFormInput
              name='address'
              label='Адреса доставки'
              id='cart-address'
              onChange={this.onChange}
              value={this.state.form.address}
            />

            <CartFormTextarea
              label='Коментар'
              id='cart-commentary'
              onChange={this.commentaryOnChange}
            />
          </div>

          <div className="col-xs-12 col-sm-6">
            <CartSubtitle name='Ваше замовлення' />
            <CartProducts
              cart={this.state.form.cart}
              amountOnChange={this.amountOnChange}
              removeProduct={this.removeProduct}
            />
            <div className={styles.sum}>
              <span className={styles.sumLabel}>Сума:</span>
              <span className={styles.sumAmount}>{roundSummary} грн.</span>
              <div className="clear" />
            </div>

            <SubmitCart name='Оформити замовлення' />
          </div>

        </form>
      </div>
    );
  }
}

export default Cart;
