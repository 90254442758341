import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import SectionTitle from '../../components/sectionTitle';
import SubmitCart from '../../components/submitCart';
import CartFormInput from '../../components/cartFormInput';
import CartFormTextarea from '../../components/cartFormTextarea';
import styles from './styles.css';

class CallbackForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: '',
        email: '',
        commentary: '',
      }
    };
  }

  onChange = (name, value) => {
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  commentaryOnChange = (ev) => {
    this.setState({
      form: {
        ...this.state.form,
        commentary: ev.target.value
      }
    });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();

    const input = {
      ...this.state.form,
    };

    this.props
      .handleSubmit({ variables: { input } })
      .then(() => {
        this.props.history.push('/callback-form/success');
      })
      .catch(err => console.log(err));
  };

  render() {
    return (
      <div className={'container'}>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="index,follow" />
          <meta name='description' content="Залиште свій відгук чи задайте питання менеджерам інтернет-магазину Хорошка" />
          <title>{"Зворотній зв'язок інтернет-магазину Хорошка. Написати нам"}</title>
          <link rel="canonical" href="https://horoshka.com.ua/feedback" />
        </Helmet>
        <SectionTitle name="Зворотній зв'язок" />
        <form onSubmit={this.handleSubmit} className={styles.wrap}>
          <CartFormInput
            name='name'
            label="Ім'я"
            id='cart-name'
            required={true}
            onChange={this.onChange}
            value={this.state.form.name}
          />
          <CartFormInput
            name='email'
            type='email'
            label='E-mail'
            id='cart-phone'
            required={true}
            onChange={this.onChange}
            value={this.state.form.email}
          />
          <CartFormTextarea
            label='Повідомлення'
            id='cart-commentary'
            onChange={this.commentaryOnChange}
          />
          <SubmitCart className={styles.button} name='Відправити' />
        </form>
      </div>
    );
  }
}

export default CallbackForm;
