import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import labels from '../../common/labels';
import SectionH1 from '../../components/sectionH1';
import ToggleList from '../../components/toggleList';
import styles from './styles.css';

const HowToOrder = ({ info }) => {
  const text = info.links[2] && info.links[2].text;

  return (
    <div className={`${styles.container} container`}>
      <Helmet>
        <meta name="robots" content="index,follow" />
        <meta charSet="utf-8" />
        <meta name='description' content="Умови використання та FAQ в магазину Хорошка" />
        <title>Умови використання та FAQ</title>
        <link rel="canonical" href="https://horoshka.com.ua/how-to-order" />
      </Helmet>
      <SectionH1 name={labels['how-to-order-title'].toUpperCase()} className={styles.title} />

      {text ? <div dangerouslySetInnerHTML={{ __html: text }} /> : (
        <ToggleList list={labels['how-to-order']} footer={labels['how-to-order-footer']} />
      )}
    </div>
  );
};

HowToOrder.propTypes = {
  info: PropTypes.object.isRequired
};


export default HowToOrder;
