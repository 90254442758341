import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import Search from '../search';
import Topper from '../topper';
import Basket from '../basket';
import CategoriesList from '../categoriesList';
import Contacts from '../contacts';
import Callback from '../callback';
import Menu from '../menu';
import styles from './styles.css';

const Header = ({ menu, social, contacts, phones, categories, info }) => (
  <div>
    <div>
      <Topper menu={menu} social={social} info={info} />
      <header className={`${styles['header-wrap']} container`}>
        <div className={`${styles.header}`}>
          <Menu info={info} />
          <Link to='/' className={`${styles.logo}`} />
          <Basket className={styles.basket} />
          <Contacts contacts={contacts} phones={phones} />
          <Callback classNameWrap={styles.callback} />
        </div>
        <div className={`${styles['feature-wrap']} row`}>
          <div className="col-xs-12 col-sm-5 d-md-none">
            <Link to='/categories' className={styles.button}>
                КАТАЛОГ ТОВАРІВ
              </Link>
          </div>
          <Search className={'d-md-none'} />
          <CategoriesList categories={categories} />
          <Search className={cx('d-xs-none d-md-flex col-md-3', styles.search)} />
          <div className={`${styles.wrapper}`}>
            <Basket className={styles['another-basket']} />
          </div>
        </div>
        <Callback
          className={styles['another-callback']}
          classNameWrap={styles['another-callback']}
        />
      </header>
    </div>
    <div className="container">
      <div className={styles.caption}>
          Шановні клієнти, будь ласка, уточнюйте актуальну ціну за телефонами або у магазині.
          Ціни на сайті можуть бути застарілими. Дякуємо за розуміння!
      </div>
    </div>
  </div>
);

Header.propTypes = {
  menu: PropTypes.object.isRequired,
  social: PropTypes.object.isRequired,
  contacts: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  phones: PropTypes.array.isRequired,
  info: PropTypes.object.isRequired
};

export default Header;
