import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import Product from '../../components/products/components/product';
import styles from './styles.css';

class FilteredProducts extends Component {
  static propTypes = {
    attributes: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    addProductToCart: PropTypes.func.isRequired,
    buy: PropTypes.func.isRequired
  };

  filterForSelectAttributes = () => {
    const { attributes, products, location } = this.props;
    if (attributes.length === 0) { return products; }
    const parseSearch = queryString.parse(location.search);
    const attributesRelations =
      attributes
        .map((attribute) => {
          if (parseSearch[attribute.id]) {
            return attribute.values.filter((value) => {
              if (!parseSearch[attribute.id]) { return null; }
              return parseSearch[attribute.id]
                && parseSearch[attribute.id].includes(value.id);
            });
          }
          return attribute.values;
        })
        .map(attribute =>
          attribute
            .map(value => value.relations))
        .map((attribute) => {
          if (attribute.length === 0) { return []; }
          return attribute.reduce((a, b) => a.concat(b));
        })
        .filter(relations => relations.length !== 0);

    if (attributesRelations.length === 0) { return attributesRelations; }

    const relations =
      attributesRelations
        .reduce((arrayA, arrayB) =>
          arrayA.filter(itemA =>
            arrayB.includes(itemA)));

    return products.filter(product => relations.includes(product.id));
  };

  filterForNumericAttributes = () => {
    const products = this.filterForSelectAttributes();
    const { location } = this.props;
    const parseSearch = queryString.parse(location.search);
    const keys = Object.keys(parseSearch).filter((key) => {
      if (key === 'price-to' || key === 'price-from') { return false; }
      return key.includes('-from') || key.includes('-to');
    });

    if (!products.attributes) { return products; }
    return products
      .filter(product =>
        product.attributes
          .find((attribute) => {
            if (attribute === null) { return false; }
            if (keys.length === 0) { return true; }
            if (!parseSearch[`${attribute.id}-from`]
              && !parseSearch[`${attribute.id}-to`]
            ) { return false; }
            const value = Number(attribute.productValues[0].value.value);
            const from = Number(parseSearch[`${attribute.id}-from`]);
            const to = Number(parseSearch[`${attribute.id}-to`]);

            if (value >= from && value <= to) { return true; }
            if (parseSearch[`${attribute.id}-from`]
              && !parseSearch[`${attribute.id}-to`]) {
              if (value >= from) { return true; }
            }
            if (!parseSearch[`${attribute.id}-from`]
              && parseSearch[`${attribute.id}-to`]) {
              if (value <= to) { return true; }
            }
            return false;
          }));
  };

  filterForPrice = () => {
    const products = this.filterForNumericAttributes();
    const { location } = this.props;
    const parseSearch = queryString.parse(location.search);
    const keys = Object.keys(parseSearch).filter(key => key.includes('price-from') || key.includes('price-to'));
    return products
      .filter((product) => {
        if (keys.length === 0) { return true; }
        if (!parseSearch['price-from']
          && !parseSearch['price-to']
        ) { return false; }

        const value = Number(product.price);
        const from = Number(parseSearch['price-from']);
        const to = Number(parseSearch['price-to']);

        if (value >= from && value <= to) { return true; }
        if (parseSearch['price-from']
          && !parseSearch['price-to']) {
          if (value >= from) { return true; }
        }

        if (!parseSearch['price-from']
          && parseSearch['price-to']) {
          if (value <= to) { return true; }
        }

        return false;
      });
  };

  sorted = () => {
    const { location } = this.props;
    const products = this.filterForPrice();
    const parseSearch = queryString.parse(location.search);
    if (!parseSearch.sort) { return products; }
    if (parseSearch.sort === 'price') {
      return sortBy(products, 'price');
    }
    return sortBy(products, (product) => {
      const attribute = product.attributes
        .find(attr => (attr && attr.id === '5a8982f836884b4eb40eecbb'));
      return attribute && attribute.productValues[0].value.value;
    });
  };

  render() {
    const { addProductToCart, buy, match, location } = this.props;
    let parseSearch = queryString.parse(location.search);
    const sorted = this.sorted();
    const products =
      sorted.filter(product => !product.modifications.every(mod => mod && mod.images.length === 0));
    const numberProducts = 12;

    const maxPage = products.length / numberProducts;
    const pageNumber = parseSearch.page || 1;
    const newProd = products.slice(0, pageNumber * numberProducts);
    const isShowMore = pageNumber >= maxPage;
    if (!isShowMore) {
      parseSearch = { ...parseSearch, page: Number(pageNumber) + 1 };
    }
    return (
      <div className="row">
        {newProd.map((product, index) => (
          <Product
            key={product.id}
            product={product}
            className={`col-xs-12 col-sm-6 col-md-4 ${styles.product}`}
            addProductToCart={addProductToCart}
            buy={buy}
            index={index}
          />
        ))}
        {!isShowMore && <div className={styles.wrap}>
          <Link
            className={cx({ [styles.active]: !isShowMore }, styles['show-more'])}
            to={{
              path: match,
              search: queryString.stringify(parseSearch)
            }}
          >
            Показати ще
          </Link>
        </div>}
      </div>
    );
  }
}

export default FilteredProducts;
