import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import labels from '../../common/labels';
import SectionH1 from '../../components/sectionH1';
import ToggleList from '../../components/toggleList';
import styles from './styles.css';

const Guarantee = ({ info }) => {
  const text = info.links[3] && info.links[3].text;
  return (
    <div className={`${styles.container} container`}>
      <Helmet>
        <meta name="robots" content="index,follow" />
        <meta charSet="utf-8" />
        <meta name='description' content="Гарантійні умови та положення інтернет-магазину Хорошка" />
        <title>Гарантія магазину horoshka.com.ua</title>
        <link rel="canonical" href="https://horoshka.com.ua/guarantee" />
      </Helmet>
      <SectionH1 name={labels['guarantee-title'].toUpperCase()} className={styles.title} />

      {text ? <div dangerouslySetInnerHTML={{ __html: text }} /> : (
        <ToggleList list={labels.guarantee} footer={labels['guarantee-footer']} />
      )}
    </div>
  );
};

Guarantee.propTypes = {
  info: PropTypes.object.isRequired
};

export default Guarantee;
