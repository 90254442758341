import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';
import Checkboxes from '../../../checkboxes';
import Numerics from '../numerics';

const Attribute = ({ attribute, match, location, history }) => (
  <div className={`${styles.attribute} col-xs-12 col-sm`}>
    <div className={styles.wrap}>
      {attribute.type === 'number' &&
        <Numerics
          attribute={attribute}
          match={match}
          location={location}
          history={history}
        />
      }
      {(attribute.type === 'select') || (attribute.type === 'select2') &&
        <Checkboxes
          attribute={attribute}
          match={match}
          location={location}
        />
      }
    </div>
  </div>
);

Attribute.propTypes = {
  attribute: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Attribute;
