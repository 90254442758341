import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

const Social = ({ social }) => (
  <div className={`${styles.social} col-xs-12`}>
    {social && social.links.map(link => (
      <a
        href={`//${link.url}`}
        key={link.id}
        target="_blank"
        rel="noopener noreferrer"
        style={{ backgroundImage: `url(images/${link.name})` }}
        className={styles.network}
      >
        {''}
      </a>
    ))}
  </div>
);

Social.propTypes = {
  social: PropTypes.object.isRequired
};

export default Social;
