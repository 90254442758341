import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import styles from './styles.css';

const createChangeHandler = (onChange, name) => ev => onChange(name, ev.target.value);

const CartFormInput = ({ label, name, value, id, required = false, mask, minLength, onChange, type = 'text' }) => (
  <label className={styles.label} htmlFor={id}>
    {label} {required && <sup className={styles.required}>*</sup>}
    {
      mask ?
        <InputMask
          className={styles.input}
          mask={mask}
          maskChar=""
          id={id}
          value={value}
          onChange={createChangeHandler(onChange, name)}
        /> :
        <input
          className={styles.input}
          id={id}
          type={type}
          required={required}
          minLength={minLength}
          value={value}
          onChange={createChangeHandler(onChange, name)}
        />
    }
  </label>
);

CartFormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  mask: PropTypes.any,
  minLength: PropTypes.any,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.any
};

CartFormInput.defaultProps = {
  required: false,
  mask: undefined,
  minLength: null,
  value: undefined,
  type: 'text'
};

export default CartFormInput;
