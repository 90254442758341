import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles.css';

const Basket = ({ className }) => {
  const cart = JSON.parse(localStorage.getItem('cart'));
  const amountArr = cart && cart.map(product => product && product.amount);
  const amount = amountArr && amountArr.length > 0 && amountArr.reduce((a, b) => a + b);
  return (
    <Link to='/cart' className={`${styles.basket} ${className}`}>
      {cart &&
        <div
          className={styles.counter}
        >
          {amount}
        </div>
      }
    </Link>
  );
};

Basket.propTypes = {
  className: PropTypes.string.isRequired
};

export default Basket;
