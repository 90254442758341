import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './styles.css';

const Menu = ({ menu }) => (
  <div className={`${styles['footer-item']}`}>
    <div className={styles['contacts-title']}>
      МЕНЮ
    </div>
    <div>
      {menu && menu.links.map(item => (
        <Link to={item.url} className={styles.item} key={item.id}>
          {item.name}
        </Link>
      ))}
    </div>
  </div>
);

Menu.propTypes = {
  menu: PropTypes.object.isRequired
};

export default Menu;
