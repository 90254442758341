import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './styles.css';

const scrollHorizontally = href => (ev) => {
  const typesWrap = document.getElementById(href);
  const delta = Math.max(-1, Math.min(1, (ev.wheelDelta || -ev.detail)));
  typesWrap.scrollLeft -= (delta * 40);
  ev.preventDefault();
};

const onWheel = href => () => {
  const typesWrap = document.getElementById(href);
  if (typesWrap.addEventListener) {
    typesWrap.addEventListener('mousewheel', scrollHorizontally(href), false);
  }
};

const TypesList = ({ types, match }) => {
  const values = types.map(type => type.value);
  const length = values.length;
  const href = match.params.href;
  if (values.length === 0) { return null; }
  const valuesLength = values.reduce((a, b) => a.concat(b)).length;
  const width = length * 30 + valuesLength * 10;
  return (
    <div id={href} className={styles.wrap} onWheel={onWheel(href)}>
      <div className={styles.scroll} style={{ minWidth: `${width}px` }}>
        {types.map(type => (
          <Link
            key={type.id}
            className={styles.link}
            to={`${match.url}/${type.href}`}
          >
            <div className={`${styles.arrow} ${styles.left}`} />
            {type.value}
            <div className={`${styles.arrow} ${styles.right}`} />
          </Link>
        ))}
      </div>
    </div>
  );
};

TypesList.propTypes = {
  types: PropTypes.array,
  match: PropTypes.object.isRequired
};

TypesList.defaultProps = {
  types: []
};

export default TypesList;
