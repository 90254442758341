import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import manufacturersQuery from './manufacturerQuery.graphql';
import productsQuery from './productsQuery.graphql';
import component from './component';
import { compose } from '../../common/utils';

export default withRouter(compose(
  graphql(manufacturersQuery, {
    options: ({ match }) => ({ variables: { href: match.params.href } }),
    props: ({ data: { manufacturer = {} } }) => ({ manufacturer })
  }),
  graphql(productsQuery, {
    options: ({ match }) => ({ variables: { manufacturer: match.params.href } }),
    props: ({ data: { products = [] } }) => ({ products })
  },
))(component));
