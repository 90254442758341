import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';
import Attribute from './components/attribute';
import Price from './components/price';

const Filter = ({ attributes, match, location, history, products, filterOn }) => (
  <div className={`row ${styles.wrap} col-12 ${filterOn && styles['show-filter']}`}>
    <Price
      products={products}
      match={match}
      location={location}
      history={history}
    />
    {attributes.map(attribute => (
      <Attribute
        attribute={attribute}
        key={`${attribute.id}`}
        match={match}
        location={location}
        history={history}
        products={products}
      />
    ))}
  </div>
);

Filter.propTypes = {
  attributes: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  filterOn: PropTypes.bool.isRequired,
};

export default Filter;
