import React from 'react';
import PropTypes from 'prop-types';
import labels from '../../../../common/labels';
import styles from './styles.css';

const Characteristics = ({ attributes, modificationAttributes }) => (
  <div className={styles.characteristics}>
    <div className={styles.title}>
      {labels.characteristics}
    </div>
    {attributes.map((attribute, index) => {
      if (!attribute || !attribute.productValues[0] || !attribute.productValues[0].value) {
        return null;
      }

      const value = (
        modificationAttributes
        && modificationAttributes[index]
        && modificationAttributes[index].productValues[0]
        && modificationAttributes[index].productValues[0].value
        && modificationAttributes[index].productValues[0].value.value
      ) || attribute.productValues[0].value.value;

      return attribute && attribute.type !== 'color' && !attribute.name.includes('Цена') && (
        <div key={attribute.id} className={styles.item}>
          <div className={styles.name}>
            {attribute.name}
          </div>
          <div className={styles.value}>
            {value}
          </div>
        </div>
      );
    })}
  </div>
);

Characteristics.propTypes = {
  attributes: PropTypes.array.isRequired,
  modificationAttributes: PropTypes.array
};

Characteristics.defaultProps = {
  modificationAttributes: []
};

export default Characteristics;
