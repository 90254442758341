import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import styles from './styles.css';

const Checkbox = ({
    id,
    label,
    match,
    location,
    attribute
  }) => {
  const parsedSearch = queryString.parse(location.search);
  const active = parsedSearch[attribute.id] && parsedSearch[attribute.id].includes(id);

  if (parsedSearch[attribute.id] === id || parsedSearch[attribute.id] === `,${id}`) {
    delete parsedSearch[attribute.id];
  } else if (active && (parsedSearch[attribute.id] !== id || parsedSearch[attribute.id] !== `,${id}`)) {
    parsedSearch[attribute.id] =
      parsedSearch[attribute.id].includes(`,${id}`)
        ? parsedSearch[attribute.id].replace(`,${id}`, '')
        : parsedSearch[attribute.id].replace(id, '');
  } else if (parsedSearch[attribute.id] === id || parsedSearch[attribute.id] === `,${id}`) {
    delete parsedSearch[attribute.id];
  } else {
    parsedSearch[attribute.id] = parsedSearch[attribute.id] ? `${parsedSearch[attribute.id]},${id}` : id;
  }

  return (
    <Link
      className={styles.checkbox}
      to={{
        pathname: match.url,
        search: queryString.stringify(parsedSearch)
      }}
    >
      <div className={styles.box} />
      <div className={styles.label}>{label}</div>
      <div className={`${styles.check} ${active && styles.active}`} />
    </Link>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  attribute: PropTypes.object.isRequired,
};

export default Checkbox;
