import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageWithFilter from '../pageWithFilter/component';

const Type = (
  {
    attributes,
    products,
    match,
    location,
    history,
    addProductToCart,
    attributesForFilterPanel,
    buy,
    type,
  }) => {
  if (type === null) { return <Redirect to="/404" />; }
  return (
    <PageWithFilter
      match={match}
      location={location}
      history={history}
      addProductToCart={addProductToCart}
      attributesForFilterPanel={attributesForFilterPanel}
      products={products}
      pageTitle={type.title || type.value}
      buy={buy}
      attributes={attributes}
      metaTags={{
        metaDescription: type.metaDescription,
        metaTitle: type.metaTitle,
        canonical: type.canonical,
      }}
      footerText={type.footerText}
    />
  );
};

Type.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  attributes: PropTypes.array.isRequired,
  attributesForFilterPanel: PropTypes.array.isRequired,
  type: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  buy: PropTypes.func.isRequired
};

export default Type;
