import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { canUseDOM } from 'exenv';
import Map from './map';
import Contacts from '../../components/contacts';
import labels from '../../common/labels';
import SectionH1 from '../../components/sectionH1';
import SectionTitle from '../../components/sectionTitle';
import styles from './styles.css';

const ContactsPage = ({ contacts, phones, info }) => {
  const text = info.links[0] && info.links[0].text;
  return (
    <div className={`${styles.container} container`}>
      <Helmet>
        <meta name="robots" content="index,follow" />
        <meta charSet="utf-8" />
        <meta name='description' content="Контакти інтернет магазину Хорошка, адреса, телефон, карта проїзду" />
        <title>Контакти інтернет магазина Хорошка</title>
        <link rel="canonical" href="https://horoshka.com.ua/contacts" />
      </Helmet>
      <SectionH1 name={labels.aboutTitle.toUpperCase()} className={styles.title} />
      <SectionTitle name={labels.aboutSubtitle.toUpperCase()} className={styles.margin} />

      <div className={`${styles.content} ${styles.margin}`} dangerouslySetInnerHTML={{ __html: text || labels.aboutDescription }} />

      <SectionTitle name={labels.aboutContacts.toUpperCase()} className={styles.margin} />
      <div className={`${styles.contentWrap} ${styles.margin}`} >
        <Contacts
          contactClassName={`${styles.contact} col-xs-7 col-sm-3 col-md-2`}
          className={`${styles.contacts} row`}
          contacts={contacts}
          phones={phones}
        />
      </div>
      <div className={styles.map}>
        {canUseDOM && <Map />}
      </div>
    </div>
  );
};

ContactsPage.propTypes = {
  contacts: PropTypes.array.isRequired,
  phones: PropTypes.array.isRequired,
  info: PropTypes.object.isRequired,
};

export default ContactsPage;
