import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import styles from './styles.css';

const Article = ({ article }) => {
  if (!article) { return null; }

  return (
    <div className='container'>
      <Helmet>
        <meta name="robots" content="index,follow" />
        <meta charSet="utf-8" />
        <meta name='description' content="" />
        <title>{article.title}</title>
        <link rel="canonical" href="" />
      </Helmet>
      <div className={styles.articleHead}>
        <h1 className={styles.title}>{article.title}</h1>
        {/* <img className={styles.image} src={article.image.path} alt={article.title} /> */}
      </div>
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: article.content }} />
    </div>
  );
};

Article.propTypes = {
  article: PropTypes.object,
};

Article.defaultProps = {
  article: undefined
};

export default Article;
