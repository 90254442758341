import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styles from './styles.css';
import BannerCarousel from '../../components/bannerCarousel';
import Articles from '../../components/articles';
import Products from '../../components/products';
import SectionTitle from '../../components/sectionTitle';
import labels from '../../../src/common/labels';

const Home = ({ popular, newProducts, addProductToCart, buy, pageText }) => (
  <div className={`${styles.home} container`}>
    <Helmet>
      <meta name="robots" content="index,follow" />
      <meta charSet="utf-8" />
      <meta name='description' content={labels['homepage-meta']} />
      <title>{labels['homepage-title']}</title>
      <link rel="canonical" href="https://horoshka.com.ua" />
    </Helmet>
    <div className={`row ${styles.banners}`}>
      <BannerCarousel />
    </div>

    <SectionTitle name='ПОПУЛЯРНЕ' />
    <Products
      title="ПОПУЛЯРНЕ"
      addProductToCart={addProductToCart}
      products={popular && popular.slice(0, 20)}
      buy={buy}
    />

    <SectionTitle name='НОВИНКИ' />
    <Products
      title="НОВИНКИ"
      addProductToCart={addProductToCart}
      products={newProducts && newProducts.slice(0, 20)}
      buy={buy}
    />

    <SectionTitle name='НОВИНИ, АКЦІЇ, СТАТТІ' />
    <Articles />
    <div className={styles['bottom-text']}>
      {pageText ? <p dangerouslySetInnerHTML={{ __html: pageText }} /> : (
        <p>
          <h1 className={styles.title} >{labels['homepage-h1'].toUpperCase()}</h1>
          <p dangerouslySetInnerHTML={{ __html: labels['homepage-sub-header'] }} />
          <h2 className={styles.subtitle} >{labels['homepage-h2-1'].toUpperCase()}</h2>
          <p dangerouslySetInnerHTML={{ __html: labels['homepage-text-1'] }} />
          <h2 className={styles.subtitle} >{labels['homepage-h2-2'].toUpperCase()}</h2>
          <p dangerouslySetInnerHTML={{ __html: labels['homepage-text-2'] }} />
        </p>
      )}
    </div>
  </div>
);

Home.propTypes = {
  popular: PropTypes.array,
  newProducts: PropTypes.array,
  addProductToCart: PropTypes.func.isRequired,
  buy: PropTypes.func.isRequired,
  pageText: PropTypes.string,
};

Home.defaultProps = {
  popular: undefined,
  newProducts: undefined,
  pageText: '',
};

export default Home;
