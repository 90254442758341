import { graphql } from '@apollo/client/react/hoc';
import component from './component';
import navQuery from './navQuery.graphql';
import contactQuery from './contactQuery.graphql';
import phonesQuery from './phonesQuery.graphql';
import categoriesQuery from './categoriesQuery.graphql';
import homePageQuery from './homePageQuery.graphql';
import { compose } from '../../common/utils';

export default compose(
  graphql(
    navQuery,
    {
      options: () => ({ variables: { id: '59ff279e7627a630b383e378' } }),
      props: ({ data: { nav = { links: [] } } }) => ({ menu: nav })
    }
  ),
  graphql(
    navQuery,
    {
      options: () => ({ variables: { id: '59ff279e7627a630b383e375' } }),
      props: ({ data: { nav = { links: [] } } }) => ({ info: nav })
    }
  ),
  graphql(
    navQuery,
    {
      options: () => ({ variables: { id: '59ff279e7627a630b383e376' } }),
      props: ({ data: { nav = { links: [] } } }) => ({ social: nav })
    }
  ),
  graphql(
    homePageQuery,
    {
      options: () => ({ variables: { url: '/' } }),
      props: ({ data: { link = { text: '' } } }) => ({ homePageText: link.text })
    }
  ),
  graphql(
    contactQuery,
    {
      props: ({ data: { contacts = [] } }) => ({ contacts })
    }
  ),
  graphql(
    phonesQuery,
    {
      props: ({ data: { phones = [] } }) => ({ phones })
    }
  ),
  graphql(
    categoriesQuery,
    {
      props: ({ data: { categories = [] } }) => ({ categories })
    }
  )
)(component);
