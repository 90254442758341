import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import manufacturersQuery from './manufacturersQuery.graphql';
import component from './component';

export default withRouter(graphql(manufacturersQuery, {
  props: ({ data: { manufacturers = [] } }) =>
    ({ manufacturers: sortBy(manufacturers, item => item.value) })
})(component));

