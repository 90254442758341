import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import labels from '../../common/labels';
import styles from './styles.css';

const CartSuccess = () => (
  <div className={styles.wrapper}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name='description' content="" />
      <title>{"Зворотній зв'язок"}</title>
      <link rel="canonical" href="" />
    </Helmet>
    <div>
      <h3 className={styles.title}>{labels.callbackForm.success.header}</h3>
      <p>
        <Link className={styles.link} to={'/'}>{labels.redirectToMain}</Link>
      </p>
    </div>
  </div>
);

export default CartSuccess;
