import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import labels from '../../common/labels';
import SectionH1 from '../../components/sectionH1';
import styles from './styles.css';

const PageNotFound = ({ staticContext }) => {
  // eslint-disable-next-line
  if (staticContext) staticContext.status = 404;

  return (
    <div className={`${styles.container} container`}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="index,follow" />
        <meta name='description' content="" />
        <title>404</title>
        <link rel="canonical" href="" />
      </Helmet>
      <SectionH1 name={labels['404Title'].toUpperCase()} className={styles.title} />
      <div className={`${styles.content} ${styles.margin}`} dangerouslySetInnerHTML={{ __html: labels['404Description'] }} />
      <p>
        <Link className={styles.link} to={'/'}>{labels.redirectToMain}</Link>
      </p>
    </div>
  );
};

PageNotFound.propTypes = {
  staticContext: PropTypes.object
};

PageNotFound.defaultProps = {
  staticContext: undefined
};

export default PageNotFound;
