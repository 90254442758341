import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

const CartAmount = ({ amount, id, amountOnChange }) => (
  <div className={styles.wrapper}>
    <button
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        amountOnChange(id, amount - 1);
      }}
      className={styles.button}
    >-</button>
    <strong className={styles.amount}>{amount}</strong>
    <button
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        amountOnChange(id, amount + 1);
      }}
      className={styles.button}
    >+</button>
  </div>
);

CartAmount.propTypes = {
  id: PropTypes.string.isRequired,
  amount: PropTypes.number,
  amountOnChange: PropTypes.func.isRequired,
};

CartAmount.defaultProps = {
  amount: 1
};

export default CartAmount;
