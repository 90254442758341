import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import categoryQuery from './categoryQuery.graphql';
import typesQuery from './typesQuery.graphql';
import manufacturersQuery from './manufacturersQuery.graphql';
import component from './component';
import { compose } from '../../common/utils';

export default withRouter(compose(
  graphql(categoryQuery, {
    options: ({ match }) => ({ variables: { href: match.params.href, important: true } }),
    props: ({ data: { category = { attributes: [], products: [] } } }) => ({ category })
  }),
  graphql(typesQuery, {
    options: ({ match }) => ({ variables: { categoryHref: match.params.href } }),
    props: ({ data: { types = [] } }) => ({ types })
  }),
  graphql(manufacturersQuery, {
    options: ({ match }) => ({ variables: { categoryHref: match.params.href } }),
    props: ({ data: { manufacturersByCategory = [] } }) => ({ manufacturersByCategory })
  }),
)(component));
