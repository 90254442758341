import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import { Helmet } from 'react-helmet';
import Sort from '../../components/sort';
import SectionH1 from '../../components/sectionH1';
import FilterPanel from '../../components/filterPanel';
import TypesLinks from '../../components/typesLinks';
import styles from './styles.css';

class PageWithFilter extends Component {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    addProductToCart: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    attributes: PropTypes.array,
    attributesForFilterPanel: PropTypes.array,
    pageTitle: PropTypes.string,
    footerText: PropTypes.string,
    buy: PropTypes.func.isRequired,
    metaTags: PropTypes.object.isRequired,
    types: PropTypes.array,
  };

  static defaultProps = {
    attributes: [],
    attributesForFilterPanel: undefined,
    pageTitle: '',
    types: [],
    footerText: '',
    match: {}
  };

  state = {
    filterOn: false,
    filtered: false,
    sorted: false
  };

  UNSAFE_componentWillMount() {
    this.addPropductsToState(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.addPropductsToState(nextProps);
  }

  addPropductsToState(props) {
    this.setState({ products: props.products });
  }

  toggleSort = () => {
    const { sorted } = this.state;
    const { products } = this.props;
    const sortedProducts = sortBy(products, 'price');
    if (!sorted) {
      return this.setState({ products: sortedProducts, sorted: !sorted });
    }
    return this.setState({ products, sorted: !sorted });
  };

  toggleFilter = () => {
    this.setState({ filterOn: !this.state.filterOn });
  };

  render() {
    const {
      attributes,
      products,
      pageTitle,
      match,
      location,
      history,
      addProductToCart,
      attributesForFilterPanel,
      metaTags,
      buy,
      types,
      footerText
    } = this.props;
    const { filterOn } = this.state;
    const description = metaTags.metaDescription ? metaTags.metaDescription : '';
    const canonical = metaTags.canonical ? metaTags.canonical : `${window.location.origin}${location.pathname}`;
    const title = metaTags.metaTitle ? metaTags.metaTitle : pageTitle;
    const footer = !footerText ? '' : footerText;
    const robots = location.search ? 'noindex,nofollow' : 'index,follow';

    return (
      <div className={`${styles.wrap} container`}>
        <Helmet>
          <meta name="robots" content={robots} />
          <meta charSet="utf-8" />
          <meta name='description' content={description} />
          <title>{title}</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        {pageTitle && <SectionH1 name={pageTitle && pageTitle.toUpperCase()} />}
        {types && <TypesLinks types={types} match={match} />}
        <div className="row">
          <FilterPanel
            attributes={attributesForFilterPanel || attributes}
            products={products}
            match={match}
            location={location}
            filterOn={filterOn}
            history={history}
            toggleFilter={this.toggleFilter}
          />
          <Sort
            attributes={attributes}
            products={products}
            match={match}
            location={location}
            history={history}
            addProductToCart={addProductToCart}
            buy={buy}
          />
        </div>
        <div
          className={styles['bottom-text']}
          dangerouslySetInnerHTML={{ __html: footer }}
        />
      </div>
    );
  }
}

export default PageWithFilter;
