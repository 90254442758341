import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageWithFilter from '../pageWithFilter';
import Type from '../type';
import PageNotFound from '../404';

const manufacurersMock = {
  id: '5a8982f836884b4eb40eecbb',
  type: 'select2',
  name: 'Виробник',
  values: [],
};

const typesMock = {
  id: '5a89903388f747506656c177',
  type: 'select2',
  name: 'Тип',
  values: [],
};

const Category =
  ({
     category,
     match,
     location,
     history,
     addProductToCart,
     buy,
     types,
     manufacturersByCategory
  }) => {
    const attributesForFilterPanel = [
      { ...manufacurersMock, values: manufacturersByCategory },
      { ...typesMock, values: types }
    ];

    if (category === null) { return null; }
    const products = category && category.products;
    return (
      <Switch>
        <Route
          exact
          path={`${match.path}`}
          render={() =>
            <PageWithFilter
              match={match}
              location={location}
              history={history}
              addProductToCart={addProductToCart}
              attributesForFilterPanel={attributesForFilterPanel}
              products={products}
              pageTitle={category.name}
              buy={buy}
              metaTags={{
                metaDescription: category.metaDescription,
                metaTitle: category.metaTitle,
                canonical: category.canonical,
              }}
              attributes={category.attributes}
              types={types}
              footerText={category.footerText}
              categoryId={category.id}
            />
          }
        />
        <Route
          path={`${match.path}/:href`}
          exact
          render={() =>
            <Type
              addProductToCart={addProductToCart}
              buy={buy}
              categoryId={category.id}
              attributes={category.attributes.filter(attribute => attribute.id !== '5a89903388f747506656c177')}
              attributesForFilterPanel={[{ ...manufacurersMock, values: manufacturersByCategory }]}
            />
          }
        />
        <Route name="404" component={PageNotFound} />
      </Switch>
    );
  };

Category.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  types: PropTypes.array.isRequired,
  manufacturersByCategory: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  buy: PropTypes.func.isRequired
};

export default Category;
