import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

const Item = ({ children }) => (<div className={styles.item}>
  {children}
</div>);

Item.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Item;
