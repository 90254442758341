import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';

import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';

import './styles.scss';

import registerServiceWorker from './registerServiceWorker';
import App from './components/app';

const client = new ApolloClient({
  link: createHttpLink({ uri: '/graphql' }),
  cache: new InMemoryCache({
    addTypename: false,
  }).restore(window.__APOLLO_STATE__),
});

const history = createHistory();

render(
  <ApolloProvider client={client}>
    <Router history={history}>
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);

registerServiceWorker();
