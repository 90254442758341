import React from 'react';
import PropTypes from 'prop-types';
import SortLink from '../link/component';
import styles from './styles.css';

const Links = ({ match, location, attributes }) => {
  const sortLinks = attributes && attributes.length !== 0 ? [
    {
      name: 'По ціні',
      type: 'price'
    },
    {
      name: 'По виробнику',
      type: 'manufacturer'
    }
  ] : [
    {
      name: 'По ціні',
      type: 'price'
    }];
  return (
    <div className={styles.wrap}>
      {sortLinks.map(link => (
        <SortLink
          key={link.type}
          match={match}
          location={location}
          link={link}
        />
      ))}

    </div>
  );
};

Links.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  attributes: PropTypes.array.isRequired,
};

export default Links;
