import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Article from './components/article';
import Slider from '../slider';
import Item from '../slider/item';

class Articles extends Component {
  static propTypes = {
    articles: PropTypes.array
  };

  static defaultProps = {
    articles: undefined
  };

  render() {
    const { articles } = this.props;
    if (!articles) {
      return null;
    }
    return (
      <Slider>
        {articles.map((article, index) => (
          <Item key={index}>
            <Article article={article} key={index} />
          </Item>
        ))}
      </Slider>
    );
  }
}

export default Articles;
