import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

const SectionTitle = ({ name, className }) => (
  <div className={`${styles['title-wrap']} ${className}`}>
    <h2 className={styles.title}>
      {name}
    </h2>
    <div className={styles.border} />
  </div>
);

SectionTitle.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

SectionTitle.defaultProps = {
  className: ''
};

export default SectionTitle;
