import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import styles from './styles.css';

const SortLink = ({ link, match, location }) => {
  const parseSearch = queryString.parse(location.search);
  if (parseSearch.sort === link.type) {
    delete parseSearch.sort;
  } else {
    parseSearch.sort = link.type;
  }
  return (
    <Link
      className={`${styles.link} ${parseSearch.sort !== link.type && styles.active}`}
      to={{
        path: match,
        search: queryString.stringify(parseSearch)
      }}
    >
      {link.name}
    </Link>
  );
};

SortLink.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  link: PropTypes.object.isRequired
};

export default SortLink;
