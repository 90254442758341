import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

const CartFormTextarea = ({ label, value, id, onChange }) => (
  <label className={styles.label} htmlFor={id}>
    {label}
    <textarea
      className={styles.textarea}
      id={id}
      type="text"
      value={value}
      onChange={onChange}
    />
  </label>
);

CartFormTextarea.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any
};

CartFormTextarea.defaultProps = {
  value: undefined
};

export default CartFormTextarea;
