import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { canUseDOM } from 'exenv';
import Markers from '../../../markers';
import styles from './styles.css';

const inCart = (addProductToCart, idToCart) => (ev) => {
  ev.preventDefault();
  ev.stopPropagation();
  addProductToCart(idToCart);
};

const addProductInCartAndBuy = (buy, idToCart) => (ev) => {
  ev.preventDefault();
  ev.stopPropagation();
  buy(idToCart);
};

const Product = ({ product = {}, className, addProductToCart, buy, index }) => {
  const image =
    product.modifications &&
    product.modifications[0] &&
    product.modifications[0].images &&
    product.modifications[0].images[0] &&
    product.modifications[0].images[0].path;

  const idToCart =
    product.modifications &&
    product.modifications[0] &&
    product.modifications[0].id;
  const price = Math.round(product.price);

  const styleBackground = (!canUseDOM && index >= 4) ? {} : { backgroundImage: `url("${image}")` };

  return (
    <div className={`${styles['product-wrap']} ${className}`}>
      <Link
        className={styles.product}
        to={`/product/${product.href}`}
      >
        <Markers product={product} />
        <div className={`${styles['product-image']}`} style={styleBackground} />
        <div className={styles.name}>{product.name}</div>
        <div className={styles.prices}>
          { product.sale &&
            <div className={styles.sale}>
              {`${product.salePrice} грн`}
            </div>
          }
          <div className={`${styles.price} ${product.sale && styles['before-price']}`}>
            {product.active && `${price} грн`}
            {!product.active && ''}
          </div>
        </div>
        <div className={styles.buttons}>
          {product.active &&
            <button
              onClick={inCart(addProductToCart, idToCart)}
              className={`${styles.button} ${styles['in-basket']}`}
            >
              В КОШИК
            </button>
          }
          {product.active &&
            <button
              className={`${styles.button} ${styles.buy}`}
              onClick={addProductInCartAndBuy(buy, idToCart)}
            >
              КУПИТИ
            </button>
          }
          {!product.active &&
            <div className={styles['sold-out']}>НЕМАЄ В ​​НАЯВНОСТІ</div>
          }
        </div>
      </Link>
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
  className: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  index: PropTypes.number,
  buy: PropTypes.func.isRequired,
};

Product.defaultProps = {
  className: '',
  index: 0,
};

export default Product;
