import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import isEqual from 'lodash/isEqual';
import ImagesCarousel from './components/ImagesCarousel';
import Characteristics from './components/characteristics';
import PricePanel from '../../components/pricePanel';
import BreadCrumbs from '../../components/breadCrumbs';
import labels from '../../common/labels';
import styles from './styles.css';

const slidesToShow = 6;

class ProductPage extends Component {
  static propTypes = {
    product: PropTypes.object,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    addProductToCart: PropTypes.func.isRequired,
    buy: PropTypes.func.isRequired
  };

  static defaultProps = {
    product: undefined
  };

  state = {
    order: true,
    modification: 0,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  getColorAttributes = product => (
    product.attributes.filter((attribute) => {
      if (!attribute) { return false; }
      if (attribute.type === 'color') { return attribute; }
      return false;
    }));

  getManufacturerAttribute = (product) => {
    const attr = product.attributes.find(attribute => attribute && attribute.name === 'Виробник');
    return attr && attr.productValues && attr.productValues[0]
      && attr.productValues[0].value && attr.productValues[0].value.value;
  };

  getFilteredModifications = (modifications, location) => {
    const { product } = this.props;
    const colorAttributes = this.getColorAttributes(product).map(colorAttr => colorAttr.id);
    const parseSearch = location && queryString.parse(location.search);
    const keys = parseSearch && Object.keys(parseSearch)
        .filter(key => colorAttributes.includes(key));

    return modifications
      .filter((modification) => {
        if (location) {
          if (modification.attributes.every(attr => attr === null)) {
            return keys.every(key => modification.attributeValues.find((attr) => {
              if (!attr) { return false; }
              return attr.id.includes(key) && parseSearch[key] === attr.value.value;
            }));
          }

          return keys.every(key => modification.attributeValues.find((attr) => {
            if (!attr) { return false; }

            if (attr.id.includes(key)) {
              return attr.id.includes(key)
                && parseSearch[key] === attr.value.value;
            }
            return false;
          }));
        }
        return true;
      });
  };

  getBreadCrumbs = () => {
    const { category, type } = this.props.product;

    return [
      {
        name: 'Каталог',
        href: '/categories'
      },
      {
        name: category.name,
        href: `/${category.href}`
      },
      {
        name: type.value,
        href: `/${category.href}/${type.href}`,
      },
      // {
      //   name,
      // }
    ];
  };
  render() {
    if (!this.props.product) { return null; }

    const {
      product,
      match,
      location,
      addProductToCart,
      buy
    } = this.props;
    const modifications = this.getFilteredModifications(product.productModifications, location);
    const modification = modifications && modifications[0];
    const breadCrumbs = this.getBreadCrumbs();
    return (
      <div vocab="http://schema.org/" typeof="Product" className={`${styles.wrap} container`}>
        <Helmet>
          <meta name="robots" content="index,follow" />
          <meta charSet="utf-8" />
          <title>{product.metaTitle}</title>
          <meta name='description' content={product.metaDescription} />
          <link rel="canonical" href="" />
        </Helmet>

        <div className={styles['top-name-block']}>
          <BreadCrumbs className={'col-xs-12'} breadCrumbs={breadCrumbs} />
          <h1 property="name" className={`${styles.name} col-xs-12`}>
            {product.name}
          </h1>
        </div>
        <div className='row'>
          <ImagesCarousel
            product={product}
            slidesToShow={slidesToShow}
            match={match}
            location={location}
            modifications={modifications}
            brand={this.getManufacturerAttribute(product)}
          />

          <div className='col-xs-12 col-sm-7 col-md-8'>
            <div className={styles['bottom-name-block']}>
              <BreadCrumbs breadCrumbs={breadCrumbs} />
              <h1 property="name" className={`${styles.name}`}>
                {product.name}
              </h1>
            </div>
            <div
              className={styles.short}
              dangerouslySetInnerHTML={{ __html: product.shortDescription }}
            />
            <PricePanel
              product={product}
              addProductToCart={addProductToCart}
              buy={buy}
              modification={modification}
            />
            <Characteristics
              attributes={product.attributes}
              modificationAttributes={modification && modification.attributes}
            />
            <div className={`${styles.content}`}>
              <div className={styles.tabs}>
                <NavLink
                  exact
                  to={`/product/${product.href}`}
                  className={styles.tab}
                  activeClassName={styles['active-tab']}
                >
                  {labels.fullDescription}
                </NavLink>
                <NavLink
                  to={`/product/${product.href}/delivery`}
                  className={styles.tab}
                  activeClassName={styles['active-tab']}
                >
                  {labels.deliveryAndPay}
                </NavLink>
              </div>
              <Route
                exact
                path={this.props.match.url}
                render={() =>
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: product.fullDescription }}
                  />
                }
              />
              <Route
                exact
                path={`${this.props.match.url}/delivery`}
                render={() =>
                  <div>
                    <div
                      className={styles.description}
                      dangerouslySetInnerHTML={{ __html: labels.delivery[0].text }}
                    />
                    <Link
                      className={styles.link}
                      to={'/delivery'}
                    >
                      Детальніше
                    </Link>
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: '' }}
        />
      </div>
    );
  }
}

export default ProductPage;
