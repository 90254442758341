import React from 'react';
import PropTypes from 'prop-types';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import styles from './styles.css';

const BannerCarousel = ({ banners }) => {
  if (!banners) { return null; }

  return (
    <CarouselProvider
      naturalSlideWidth={100}
      visibleSlides={1}
      totalSlides={banners.length}
      lockOnWindowScroll={true}
      isPlaying={true}
      className='col-md-12'
    >
      <Slider>
        {banners.map((banner, index) => {
          if (!banner.image) { return null; }

          return (
            <Slide
              key={index}
              index={index}
              className={styles.slide}
            >
              <a
                key={banner.id}
                href={banner.href}
                className={styles.banner}
              >
                <img alt={banner.href} src={`${banner.image.path}`} />
              </a>
            </Slide>
          );
        })}
      </Slider>
      <DotGroup className='control-dots' />
    </CarouselProvider>
  );
};

BannerCarousel.propTypes = {
  banners: PropTypes.array
};

BannerCarousel.defaultProps = {
  banners: undefined
};

export default BannerCarousel;
