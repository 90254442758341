import React from 'react';
import styles from './styles.css';

const Description = () => (
  <div className={`${styles.wrap}`}>
    <div className={`${styles['logo-wrap']}`}>
      <img alt="" src="/images/logo.png" className={`${styles.logo}`} />
    </div>

    <div className={`${styles.description}`}>
      Ми гарантуємо, що вся продукція для дітей,
      представлена в наших магазинах якісна, безпечна
      та не нашкодить дитині. Всі дитячі товари сертифіковані,
      що є дуже важливим, адже поруч з цими предметами малюк проводить більшу частину часу.
    </div>
  </div>
);

export default Description;
