import { graphql } from '@apollo/client/react/hoc';
import component from './component';
import bannerQuery from './bannerQuery.graphql';

export default graphql(
    bannerQuery,
  {
    props: ({ data: { banners } }) => ({ banners })
  }
  )(component);
