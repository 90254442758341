import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import sortBy from 'lodash/sortBy';
import styles from './styles.css';
import Numeric from '../numeric';

const price = 'price';

class Price extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  onChangeTo = ({ value }) => {
    const { match, history, location } = this.props;
    const parseSearch = queryString.parse(location.search);
    return history.push({
      path: match,
      search: queryString.stringify({
        ...parseSearch,
        [`${price}-to`]: Number(value),
      })
    });
  };

  onChangeFrom = ({ value }) => {
    const { match, history, location } = this.props;
    const parseSearch = queryString.parse(location.search);

    return history.push({
      path: match,
      search: queryString.stringify({
        ...parseSearch,
        [`${price}-from`]: Number(value),
      })
    });
  };

  onChangeComplete = (value) => {
    const { history, match, location } = this.props;
    const parseSearch = queryString.parse(location.search);
    history.push({
      path: match,
      search: queryString.stringify({
        ...parseSearch,
        [`${price}-from`]: value.min,
        [`${price}-to`]: value.max,
      })
    });
  };

  getMaxValue = (values) => {
    if (!values || values.length === 0) { return 0; }
    const sorted = sortBy(values, [item => Number(item.price)]);
    const length = sorted.length;
    return sorted[length - 1].price;
  };

  render() {
    const { products, match, location, history } = this.props;
    const parseSearch = queryString.parse(location.search);
    return (
      <div className={`${styles.attribute} col-xs-12 col-sm`}>
        <div className={styles.title}>
          Ціна
        </div>
        <div className={styles.wrap}>
          <Numeric
            match={match}
            location={location}
            history={history}
            onChangeFrom={this.onChangeFrom}
            onChangeTo={this.onChangeTo}
            onChangeValue={this.onChangeComplete}
            min={parseSearch[`${price}-from`] && Number(parseSearch[`${price}-from`])}
            max={parseSearch[`${price}-to`] && Number(parseSearch[`${price}-to`])}
            getMaxValue={this.getMaxValue}
            values={products}
            label={'грн'}
          />
        </div>
      </div>
    );
  }
}

export default Price;
